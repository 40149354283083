import Dashboard from 'views/Dashboard/Dashboard'
import DonationsPage from 'views/Donations/Donations'
import FeesPage from 'views/Fees/Fees'
import ActivitiesPage from 'views/Activities/Activities'
import ItemView from 'views/ItemView/ItemView'
import CartView from 'views/Cart/Cart'
import NewPaymentMethod from 'views/Profile/NewPaymentMethod'
import LunchesView from 'views/Lunches/Lunches'
import ChildcareView from 'views/Childcare/Childcare'


const storeRoutes = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
    },
    {
        path: '/donations',
        name: 'Donations',
        component: DonationsPage,
    },
    {
        path: '/fees',
        name: 'Fees',
        component: FeesPage,
    },
    {
        path: '/activities',
        name: 'Activities',
        component: ActivitiesPage,
    },
    {
        path: '/profile/new-payment-method',
        name: 'New Payment Method',
        component: NewPaymentMethod,
    },
    {
        path: '/lunches',
        name: 'Lunches',
        component: LunchesView
    },
    {
        path: '/childcare',
        name: 'Childcare',
        component: ChildcareView
    },
];

export default storeRoutes;