import { api_call, make_response_object } from 'api/base'
import { param } from 'jquery';


export async function login(body) {
    const r = await api_call('/auth/login', 'POST', body);
    return await make_response_object(r)
}


export async function logout() {
    return await api_call('/auth/logout', 'POST').ok
}


export async function checkAuth() {
    const r = await api_call('/auth/check')
    return r.ok
}


export async function whoAmI() {
    const r = await api_call('/auth/whoami', 'GET')
    return await make_response_object(r)
}


export async function getUserFromRegistrationToken(params) {
    const r = await api_call('/auth/register', 'GET', {}, params)
    return await make_response_object(r)
}


export async function register(body) {
    const r = await api_call('/auth/register', 'POST', body)
    return await make_response_object(r)
}


export async function recover(body) {
    const r = await api_call('/auth/recover', 'POST', body)
    return await make_response_object(r)
}


export async function getUserFromPasswordResetToken(params) {
    const r = await api_call('/auth/reset_password', 'GET', {}, params)
    return await make_response_object(r)
}


export async function resetPassword(body) {
    const r = await api_call('/auth/reset_password', 'POST', body)
    return await make_response_object(r)
}


export async function discover(params) {
    const r = await api_call('/auth/discover', 'GET', {}, params)
    return await make_response_object(r)
}