import React from 'react';


export const CartContext = React.createContext([ [], () => [] ])

export const CartProvider = (props) => {
    const [cart, setCart] = React.useState([])

    var cCart = localStorage.getItem('appCart')
    if (cCart === null) {
        cCart = []
    }
    else {
        cCart = JSON.parse(cCart)
    }

    function addToCart(item) {
        const newCart = [...cCart, item]
        localStorage.setItem('appCart', JSON.stringify(newCart))
        setCart(newCart)
    }

    function removeFromCart(idx) {
        const newCart = cCart.slice()
        newCart.splice(idx, 1)
        localStorage.setItem('appCart', JSON.stringify(newCart))
        setCart(newCart)
    }

    function removeAllFromCart() {
        localStorage.setItem('appCart', JSON.stringify([]))
        setCart([])
    }

    return (
        <CartContext.Provider value={[cCart, addToCart, removeFromCart, removeAllFromCart]}>
            {props.children}
        </CartContext.Provider>
    )
}