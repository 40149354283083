import Profile from 'views/Profile/Profile'
import PaymentMethodsView from 'views/Profile/PaymentMethods'
import NewPaymentMethod from 'views/Profile/NewPaymentMethod'
import TransactionsView from 'views/Profile/Transactions'
import SubscriptionsView from 'views/Profile/Subscriptions'
import UserInfo from 'views/Profile/UserInfo'


const profileRoutes = [
    {
        path: '',
        name: 'Profile',
        component: Profile
    },
    {
        path: '/user-info',
        name: 'User Info',
        component: UserInfo
    },
    {
        path: '/payment-methods',
        name: 'Payment Methods',
        component: PaymentMethodsView
    },
    {
        path: '/payment-methods/new',
        name: 'New Payment Method',
        component: NewPaymentMethod
    },
    {
        path: '/transactions',
        name: 'Transactions',
        component: TransactionsView
    },
    {
        path: '/subscriptions',
        name: 'Subscriptions',
        component: SubscriptionsView
    },
]

export default profileRoutes