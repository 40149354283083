import React from 'react';
import { Switch, Route, Redirect, Link } from "react-router-dom";
import logo from "assets/img/csplogo.png";
import 'assets/css/styles.css';
import Navbar from 'components/Navbar/Navbar'
import Dashboard from 'views/Dashboard/Dashboard'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet'
import styles from "assets/jss/storefront/layouts/storeStyle.js";
import { BottomNavigation } from '@material-ui/core'




const useStyles = makeStyles(styles);

function BaseLayout(props) {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const classes = useStyles()


    const switchRoutes = (
        <Switch>
            {props.routes.map((prop, key) => {
                console.log(props.baseUrl + prop.path)
                console.log(prop.component)
                return (
                    <Route
                        exact
                        path={props.baseUrl + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            })}
            {/* <Redirect from='/store' to='/store/dashboard' /> */}
        </Switch>
    )



    return (
        <div className={classes.wrapper}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Charter Pay</title>
                <meta content="width=device-width, initial-scale=1" name="viewport" />
                <meta content="Webflow" name="generator" />
                <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
                <link href={logo} rel="shortcut icon" type="image/x-icon" />
                <link href={logo} rel="apple-touch-icon" />
            </Helmet>
            <Navbar
                logoLink={props.logoLink}
                baseUrl={props.baseUrl}
            />
            <div className={classes.content}>
                {switchRoutes}
            </div>
            <div className={classes.footer}>
                <Typography
                    style={{ color: 'white' }}
                >
                    &copy;&nbsp;{new Date().getFullYear()}&nbsp;
                    <a
                        href='https://www.chartersuccesspartners.com/'
                        target='_blank'
                        style={{ color: 'white', textDecoration: 'none' }}
                    >
                        Charter Success Partners
                    </a>
                </Typography>
            </div>
        </div>
    );
}

export default BaseLayout;
