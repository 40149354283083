import { faCreditCard, faMoneyCheck, faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, ListItemText } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { make_filter_params } from 'api/base';
import { listCustomerOrders } from 'api/customerorders';
import Preloader from 'components/Preloader/Preloader';
import { UserContext } from 'components/UserProvider.js';
import currency from 'currency.js';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React from 'react';


// TODO: server-side pagination and sorting
export default function TransactionsView(props) {
    const currentUser = React.useContext(UserContext)[0]
    const [orders, setOrders] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [expanded, setExpanded] = React.useState({})
    const { enqueueSnackbar } = useSnackbar()


    React.useEffect(() => {
        if (props.location.state !== undefined) {
            if (props.location.state.anonUser) {
                setOrders([props.location.state.order,])
                setLoading(false)
                return
            }
        }

        async function init() {
            let filters = []
            const params = make_filter_params(filters)
            const r = await listCustomerOrders(currentUser.id, params)
            if (r.ok) {
                setOrders(r.data.results)
            }
            else {
                enqueueSnackbar('There was an error loading your transactions.', {variant: 'error'})
            }
            setLoading(false)
        }

        init()
    }, [])


    React.useEffect(() => {
        if (props.location.state !== undefined) {
            if (props.location.state.expandOrder) {
                let exp = { ...expanded }
                exp[props.location.state.expandOrder] = true
                setExpanded(exp)
            }
        }
    }, [orders])


    const handleExpandChange = (panel) => (event, isExpanded) => {
        let exp = { ...expanded }
        exp[panel] = isExpanded
        setExpanded(exp)
    }


    if (loading) { return <Preloader /> }

    if (orders.length === 0) {
        return (
            <Typography>
                No transactions were found.
            </Typography>
        )
    }

    return (
        <>
        {props.location.state === undefined ? null :
            !props.location.state.expandOrder ? null :
                <Typography style={{fontSize: 22}}>
                    Thank you! Your transaction details are below. 
                </Typography>
        }
        {orders.map(o => (
            <ExpansionPanel
                key={o.id}
                expanded={expanded[o.id] === true}
                onChange={handleExpandChange(o.id)}
                style={{marginBottom: '1em'}}
            >
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    style={{ backgroundColor: '#effbf2'}}
                >
                    <Grid container spacing={2}>
                        <Grid item style={{ width: '15%' }}>
                            <Typography style={{fontWeight: 'bold'}}>
                                {new moment(o.create_time).format('ddd, MMM Do, YYYY')}
                            </Typography>
                        </Grid>
                        <Grid item style={{width: '15%'}}>
                            <Typography>
                                Total: {String(currency(o.total_paid))}
                            </Typography>
                        </Grid>
                        <Grid item style={{ width: '25%' }}>
                            <Typography>
                                Items: {o.order_items[0].store_item_permanent.display_name}
                                <span style={{fontSize: 12}}>
                                    {o.order_items.length > 1 ? ', and ' + String(o.order_items.length - 1) + ' more...' : ''}
                                </span>
                            </Typography>
                        </Grid>
                        {!(currency(o.subtotal) == 0) &&
                        <Grid item style={{ display: 'flex', alignItems: 'center', width: '25%' }}>
                            {!o.cash_payment && o.payment_method.method_type === 'card' &&
                                <>
                                    <FontAwesomeIcon
                                        icon={faCreditCard}
                                        size='2x'
                                        color='#253c96'
                                    />
                                    <Typography
                                        style={{marginLeft: '1em'}}
                                    >
                                            {o.payment_method.card_brand.charAt(0).toUpperCase() + o.payment_method.card_brand.substring(1) + ' ending with ' + o.payment_method.last_four}
                                    </Typography>
                                </>
                            }
                            {!o.cash_payment && o.payment_method.method_type === 'bank_account' &&                    
                                <>
                                    <FontAwesomeIcon
                                        icon={faMoneyCheck}
                                        size='2x'
                                        color='#259646'
                                    />
                                    <Typography
                                        style={{ marginLeft: '1em' }}
                                    >
                                        {o.payment_method.bank_name + ' account ending in ' + o.payment_method.last_four}
                                    </Typography>
                                </>
                            }
                            {o.cash_payment &&
                            (
                                <>
                                    <FontAwesomeIcon
                                        icon={faMoneyBillAlt}
                                        size='2x'
                                        color='#85bb65'
                                    />
                                    <Typography
                                        style={{ marginLeft: '1em' }}
                                    >
                                        Cash
                                    </Typography>

                                </>
                            )
                            }
                        </Grid>
                    }
                    </Grid>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{display: 'block'}}>
                    <Box style={{marginBottom: '2em'}}>
                        <Typography style={{ fontSize: 12 }}>
                            Transaction ID: {o.id}
                        </Typography>
                        <Typography>
                            Subtotal: {String(currency(o.subtotal))}
                        </Typography>
                        <Typography>
                            Fees: {String(currency(o.fees_subtotal))}
                        </Typography>
                        <Typography>
                            Total: {String(currency(o.total_paid))}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography>
                            Items - {o.order_items.length} Total:
                        </Typography>
                        {o.order_items.map(item => (
                            <>
                            <Typography key={item.id}>
                                {item.store_item_permanent.display_name} - {String(currency(item.paid_amount))}
                            </Typography>
                            {(item.category_type === 'lunches' || item.category_type === 'fees') &&
                                    <ListItemText
                                    primary={item.student_permanent.first_name + ' ' + item.student_permanent.last_name}
                                        secondary={item.lunch_date}
                                    />
                            }
                            <br />
                            </>
                        ))}
                    </Box>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        ))}
        </>
    )
}