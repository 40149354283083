import { faCreditCard, faMoneyCheck, faTrashAlt, faHandHoldingUsd, faUtensils, faDollarSign, faBusAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Paper, FormLabel, TextField, Tooltip } from '@material-ui/core';
import Button from "@material-ui/core/Button";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { loadStripe } from '@stripe/stripe-js';
import { calculateTotals, checkout } from 'api/cart';
import { listPaymentMethods } from 'api/paymentmethods';
import stripeLogo from 'assets/img/powered_by_stripe.png';
import styles from "assets/jss/storefront/views/dashboardStyle.js";
import { CartContext } from 'components/CartProvider.js';
import { SubmitButton } from 'components/CustomButtons/Button';
import Preloader from 'components/Preloader/Preloader';
import { UserContext } from 'components/UserProvider.js';
import currency from 'currency.js';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Link, useHistory } from "react-router-dom";
import moment from 'moment';
import { faBellSchool } from '@fortawesome/pro-solid-svg-icons';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { ControlledInputInternals } from 'components/CustomInput/CustomInput.js'





const useStyles = makeStyles(styles)


export default function CartView(props) {
    const classes = useStyles()
    const currentUser = React.useContext(UserContext)[0]
    const [currentCart, addToCart, removeFromCart, removeAllFromCart] = React.useContext(CartContext)
    const [subtotal, setSubtotal] = React.useState(currency(0))
    const [step, setStep] = React.useState('view-cart')
    const [paymentSelectLoading, setPaymentSelectLoading] = React.useState(true)
    const [checkoutLoading, setCheckoutLoading] = React.useState(true)
    const [paymentMethods, setPaymentMethods] = React.useState([])
    const [chosenPaymentMethod, setChosenPaymentMethod] = React.useState('')
    const [totals, setTotals] = React.useState({})
    const [submitting, setSubmitting] = React.useState(false)
    const [success, setSuccess] = React.useState(false)
    const [cardSubmitting, setCardSubmitting] = React.useState(false)
    const [cardSuccess, setCardSuccess] = React.useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const stripe = useStripe();
    const elements = useElements();
    let history = useHistory()
    const [formState, dispatch, basicInputOnChange, onChange] = ControlledInputInternals()
   

    const anonUser = Object.keys(currentUser).length === 0

    React.useEffect(() => {
        if (props.location.state !== undefined) {
            if (props.location.state.step) {
                setStep(props.location.state.step)
            }
        }
    }, [props])

    React.useEffect(() => {

        let sT = currency(0)
        currentCart.map((item) => {
            sT = sT.add(item.cost)
        })
        setSubtotal(sT)
    }, [currentCart])


    React.useEffect(() => {
        if (step === 'payment-select') {
            if (subtotal == 0) {
                setStep('checkout')
                return
            }

            if(anonUser) {
                setPaymentSelectLoading(false)
                return
            }

            async function initPaymentSelect() {
                const r = await listPaymentMethods(currentUser.id)
                if (r.ok) {
                    setPaymentMethods(r.data.results)
                }
                setPaymentSelectLoading(false)
            }

            initPaymentSelect()
        }

        if (step === 'checkout') {
            async function initCheckout() {
                const r = await calculateTotals({
                    cart: currentCart,
                    payment_method_type: chosenPaymentMethod.method_type,
                })
                if (r.ok) {
                    setTotals(r.data)
                }
                else {
                    // enqueueSnackbar('An error occurred', {variant: 'error'})
                    // history.push('/')
                    enqueueSnackbar(r.data.text, { variant: 'error' })
                }
                setCheckoutLoading(false)
            }
            if (subtotal == 0 ) {
                setTotals({subtotal: '0.00', fees_subtotal: '0.00', total: '0.00'})
                setCheckoutLoading(false)
                return
            }
            initCheckout()
        }

    }, [step])


    function handlePaymentSelectSubmit(e) {
        e.preventDefault()
        if (!document.getElementById('payment-select-form').reportValidity()) {
            return
        }
        setStep('checkout')
    }


    async function handleCheckout(e) {
        e.preventDefault()
        
        if (anonUser) {
            const form = document.querySelector('form')
            if (!form.reportValidity()) {
                return
            }
        }

        setSubmitting(true)

        let r = null;

        if (anonUser) {
            r = await checkout(
                {
                    cart: currentCart,
                    payment_method: chosenPaymentMethod,
                    user: {
                        first_name: formState.first_name,
                        last_name: formState.last_name,
                        email: formState.email,
                        create_account: false,
                    }
                },
                {
                    public: true,
                    free: subtotal == 0
                }
            )
        }

        else if (subtotal == 0) {
            r = await checkout(
                {
                    cart: currentCart,
                },
                {
                    free: true
                }
            )
        }

        else {
            r = await checkout({
                cart: currentCart,
                payment_method_id: chosenPaymentMethod.id,
            })
        }
        if (r.ok) {
            setSuccess(true)
            removeAllFromCart()
            setSubmitting(false)
            if (anonUser) {
                history.push({
                    pathname: '/cart/order',
                    state: {
                        anonUser: true,
                        expandOrder: r.data.id,
                        order: r.data
                    }
                })
            }
            else {
                history.push({
                    pathname: '/profile/transactions',
                    state: { expandOrder: r.data.id }
                })
            }
        }
        else {
            enqueueSnackbar(r.data.text, {variant: 'error'})
        }
        setSubmitting(false)
    }


    function renderCartRow(item, i, showDelete) {
        
        const itemCategoryDisplayName = {
            'donations': 'Donations',
            'lunches': 'Lunches',
            'childcare': 'Before & After School / Childcare',
            'fees': 'Fees',
            'activities': 'Activities & Field Trips',
        }

        const itemCategoryIcon = {
            'donations': faHandHoldingUsd,
            'lunches': faUtensils,
            'childcare': faBellSchool,
            'fees': faDollarSign,
            'activities': faBusAlt,
        }

        const cellStyle = {display: 'inlineBlock'}

        function GridCell(props) {
            return (
                <Grid
                    item
                >
                    {props.children}
                </Grid>
            )
        }

        function GridRowPaper(props) {
            return (
                <Paper 
                    elevation={12}
                >
                    {props.children}
                </Paper>
            )
        }
        
        return (
            <Grid
                container
                item
                key={i}
                spacing={2}
                justifyContent='flex-start'
                alignItems='center'
                component={Paper}
                style={{marginBottom: '1em'}}
            >
                <GridCell>
                    <ListItemText
                        primary={item.store_item.display_name}
                        secondary={item.store_item.school.display_name}
                    />
                </GridCell>
                <GridCell>
                    <Grid
                        container
                        alignItems='center'
                        spacing={1}
                    >
                        <Grid item>
                            <FontAwesomeIcon
                                icon={itemCategoryIcon[item.store_item.category_type]}
                            />
                        </Grid>
                        <Grid item>
                            <ListItemText
                                primary={itemCategoryDisplayName[item.store_item.category_type]}
                            />
                        </Grid>
                    </Grid>
                </GridCell>
                <GridCell>
                    {(item.store_item.category_type === 'lunches' || item.store_item.category_type === 'fees') &&
                        <ListItemText
                            primary={item.student.first_name + ' ' + item.student.last_name}
                            secondary={item.lunch_date_cart_view}
                        />
                    }
                    {item.store_item.category_type === 'childcare' &&
                        <ListItemText
                            primary={item.childcare_students.map((s, i) => (
                                s.first_name + (i === item.childcare_students.length - 1 ? '' : ', ')
                            ))}
                        />
                    }
                </GridCell>
                <GridCell>
                    {'$' + item.cost}
                </GridCell>
                <GridCell>
                    {showDelete &&
                        <IconButton
                            onClick={() => removeFromCart(i)}
                            color='inherit'
                        >
                            <Tooltip title={'Remove ' + item.store_item.display_name + ' from cart'}>
                                <span>
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </span>
                            </Tooltip>
                        </IconButton>
                    }
                </GridCell>
            </Grid>
        )
    }


    if (step === 'view-cart') {
        return (
            <>
                <Typography>
                    <Box fontSize={22}>
                        Cart
                    </Box>
                </Typography>
                <br />
                {currentCart.length === 0 ? 'Your cart is empty.' : 
                    currentCart.map((item, i) => (
                        renderCartRow(item, i, true)
                    ))
                }
                <br /><br />
                <Grid
                    container
                    direction='row'
                    justify='flex-end'
                    alignItems='center'
                >
                    <Typography
                        style={{marginRight: 10, fontWeight: 'bold', fontSize: 18}}
                    >
                        {'$' + subtotal.format()}
                    </Typography>
                    <Button
                        color='primary'
                        variant='contained'
                        onClick={() => setStep('payment-select')}
                        disabled={currentCart.length === 0}
                    >
                        Checkout
                    </Button>
                </Grid>
                <Grid
                    container
                    direction='row'
                    justify='flex-end'
                    alignItems='center'
                >
                    <Typography>
                        Additional processing fees may apply.
                    </Typography>
                </Grid>
            </>
        )
    }

    else if (step === 'payment-select') {
        if (paymentSelectLoading) { return <Preloader /> }

        if (anonUser) {

            const CARD_ELEMENT_OPTIONS = {
                style: {
                    base: {
                        color: '#32325d',
                        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                        fontSmoothing: 'antialiased',
                        fontSize: '16px',
                        '::placeholder': {
                            color: '#aab7c4'
                        }
                    },
                    invalid: {
                        color: '#fa755a',
                        iconColor: '#fa755a'
                    }
                }
            }

            
            async function handleCardSubmit(e) {
                e.preventDefault()

                setCardSubmitting(true)
                setCardSuccess(false)

                const card = elements.getElement(CardElement)

                const stripeR = await stripe.createToken(card)
                if (stripeR.error) {
                    enqueueSnackbar(stripeR.error.message, { variant: 'error' })
                    setCardSubmitting(false)
                    return
                }
                
                const stripeCard = stripeR.token.card
                setChosenPaymentMethod({
                    token: stripeR.token.id,
                    method_type: 'card',
                    card_brand: stripeCard.brand,
                    last_four: stripeCard.last4,
                    card_exp_month: stripeCard.exp_month,
                    card_exp_year: stripeCard.exp_year,
                })
                setCardSuccess(true)
                setStep('checkout')
            }


            return (
                <>
                <Typography
                    style={{ fontSize: 20 }}
                >
                    Enter your card information. Your card information will not be saved.
                </Typography>
                <Typography
                    style={{ fontSize: 14 }}
                >
                    To pay with a bank account, please sign up. Verification of your bank account will take one to two business days.
                </Typography>
                <br />
                    <form id='card-form' onSubmit={handleCardSubmit}>
                        <FormLabel>
                            Credit or debit card
                    </FormLabel>
                        <CardElement
                            id='card-element'
                            options={CARD_ELEMENT_OPTIONS}
                            onChange={null}
                        />
                        <br /><br />
                        <Box>
                            <img src={stripeLogo} />
                            <Typography>
                                {'Your card information is stored securly by '}
                                <a href='https://stripe.com' target='_blank' rel='noopener'>Stripe</a>
                            </Typography>
                        </Box>
                        <br /><br />
                        <Grid
                            container
                            direction='row'
                            justify='flex-end'
                            alignItems='center'
                        >
                            <SubmitButton
                                submitting={cardSubmitting}
                                success={cardSuccess}
                                buttonText={'Use this Card'}
                                onClick={handleCardSubmit}
                            />
                        </Grid>
                    </form>
                </>
            )
        }

        return (
            <form id='payment-select-form' onSubmit={handlePaymentSelectSubmit}>
                <Typography
                    style={{ fontSize: 20 }}
                >
                    Select a Payment Method
                </Typography>
                <br />
                { paymentMethods.length === 0 ? 'No payment methods found.' :
                    <>
                        <FormControl component='fieldset'>
                            <RadioGroup
                            name='paymentMethod'
                            value={chosenPaymentMethod.id}
                            onChange={(e) => setChosenPaymentMethod(paymentMethods.find(m => m.id === e.target.value))}>
                                {paymentMethods.map(m => (
                                    <FormControlLabel value={m.id} control={<Radio required disabled={m.method_type === 'bank_account' && m.status !== 'verified'}/>} label={
                                        (m.method_type === 'card' ?
                                            <Grid container spacing={1}>
                                                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                                                    <FontAwesomeIcon
                                                        icon={faCreditCard}
                                                        size='2x'
                                                        color='#253c96'
                                                    />
                                                </Grid>
                                                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Typography>
                                                        {m.card_brand.charAt(0).toUpperCase() + m.card_brand.substring(1) + ' ending with ' + m.last_four}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        :
                                            <Grid container spacing={1}>
                                                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                                                    <FontAwesomeIcon
                                                        icon={faMoneyCheck}
                                                        size='2x'
                                                        color='#259646'
                                                    />
                                                </Grid>
                                                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Typography>
                                                        {m.bank_name + ' account ending in ' + m.last_four} {m.method_type === 'bank_account' && m.status !== 'verified' && '(unverified)'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )
                                    } />
                                ))}
                            </RadioGroup>
                        </FormControl>
                        <br />
                    </>
                }
                <Box
                    style={{marginTop: 25}}
                >   <Link to='/profile/payment-methods/new'>
                        <Button
                            variant='contained'
                        >
                            Add a new payment method
                        </Button>
                    </Link>
                </Box>
                <Grid
                    container
                    direction='row'
                    justify='flex-end'
                    alignItems='center'
                >
                    <Button
                        variant='contained'
                        color='primary'
                        type='submit'
                    >
                        Use this payment method
                    </Button>
                </Grid>
            </form>
        )
    }

    else if (step === 'checkout') {
        if (checkoutLoading) { return <Preloader /> }

        return (
            <>

            {currentCart.map((item, i) => (
                renderCartRow(item, i, false)
            ))}
                <br /><br />
                <Grid
                    container
                    direction='row'
                    justify='flex-end'
                    alignItems='center'
                >
                    {subtotal != 0 &&
                    <Grid item style={{width: '500px'}}>
                        <img src={stripeLogo} />
                        <Typography>
                            {'Your payment is processed securly by '}
                            <a href='https://stripe.com' target='_blank' rel='noopener'>Stripe</a>
                        </Typography>
                        <br /><br />
                        <Typography component='span' style={{fontWeight: 'bold'}}>
                            Payment Method
                        </Typography>
                        <br/>
                        <Typography component='span' style={{ marginLeft: 'auto' }}>
                            {chosenPaymentMethod.method_type === 'card' ?
                                chosenPaymentMethod.card_brand.charAt(0).toUpperCase() + chosenPaymentMethod.card_brand.substring(1) + ' ending with ' + chosenPaymentMethod.last_four
                            :
                                chosenPaymentMethod.bank_name + ' account ending in ' + chosenPaymentMethod.last_four
                            }
                        </Typography>
                    </Grid>
                    }
                    <Grid
                        container
                        item
                        direction='column'
                        justify='center'
                        alignItems='flex-end'
                        style={{ width: '250px', border: 'none' }}
                    >
                            <Grid item>
                                <Typography style={{fontWeight: 'bold', fontSize: 18}}>
                                    Order Summary
                                </Typography>
                            </Grid>
                            <Grid item container>
                                <Typography component='span'>
                                    Items:
                                </Typography>
                                <Typography component='span' style={{ marginLeft: 'auto' }}>
                                    {currentCart.length}
                                </Typography>
                            </Grid>
                            <br />
                            <Grid item container>
                                <Typography component='span'>
                                    Subtotal:
                                </Typography>
                                <Typography component='span' style={{marginLeft: 'auto'}}>
                                    {'$' + currency(totals.subtotal).format()}
                                </Typography>
                            </Grid>
                            <Grid item container>
                                <Typography component='span'>
                                    {'Fees' + (currency(totals.fees_subtotal) > currency(0) ? '*' : '') + ':'}
                                </Typography>
                                <Typography component='span' style={{ marginLeft: 'auto' }}>
                                    {'$' + currency(totals.fees_subtotal).format()}
                                </Typography>
                            </Grid>
                            <Grid item container>
                                <Typography component='span' style={{fontWeight: 'bold'}}>
                                    Total:
                                </Typography>
                            <Typography component='span' style={{ marginLeft: 'auto', fontWeight: 'bold' }}>
                                    {'$' + currency(totals.total).format()}
                                </Typography>
                            </Grid>
                            {currency(totals.fees_subtotal) > currency(0) ?
                                <Grid item>
                                    <br />
                                    <Typography component='span'>
                                        *Processing fees.
                                    </Typography>
                                </Grid>
                            :
                                null
                            } 
                    </Grid>
                </Grid>
                <br />
                <Grid
                    container
                    direction="column"
                    justify="flex-end"
                    alignItems="flex-end"
                    spacing={1}
                >
                    <form>
                    {anonUser &&
                    <>
                        <Grid item>
                            <Typography>
                                Customer Information
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                id='first_name'
                                value={formState.first_name}
                                onChange={basicInputOnChange}
                                label='First Name'
                                variant='outlined'
                                required
                            />
                        </Grid>
                        <br/>
                        <Grid item>
                            <TextField
                                id='last_name'
                                value={formState.last_name}
                                onChange={basicInputOnChange}
                                label='Last Name'
                                variant='outlined'
                                required
                            />
                        </Grid>
                        <br/>
                        <Grid item>
                            <TextField
                                id='email'
                                value={formState.email}
                                onChange={basicInputOnChange}
                                label='Email'
                                variant='outlined'
                                required
                            />
                        </Grid>
                        <br/><br/>
                    </>
                    }
                    <Grid item>
                        <SubmitButton
                            submitting={submitting}
                            success={success}
                            buttonText={'Place your order'}
                            onClick={handleCheckout}
                        />
                    </Grid>
                    </form>

                </Grid>
            </>
        )
    }
}