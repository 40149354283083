import React from 'react'
import { Link, Typography } from '@material-ui/core';


export default function NeedHelp() {
    return (
        <>
            <Typography
                style={{ fontSize: 24 }}
            >
                <Link href='https://cspsoftware.helpdocs.com/getting-started' target='_blank' rel='noopener'>
                    View Help Docs
                </Link> 
            </Typography>
            <br />
            <Typography
                style={{fontSize: 24}}
            >
                Contact Us
            </Typography>
            <p>
                <Link href='mailto:support@chartersuccesspartners.com'>
                    support@chartersuccesspartners.com
                </Link>
                <br/>
                <Link href='tel:888-485-4643'>
                    888-485-4643
                </Link>
            </p>
        </>
    );
}