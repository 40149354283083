import React from 'react';
import BaseLayout from 'layouts/Base'
import routes from './ProfileRoutes'


function Profile() {
    return (
        <BaseLayout
            routes={routes}
            baseUrl='/profile'
        />
    )
}

export default Profile