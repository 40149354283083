import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from "assets/jss/storefront/components/buttonStyle.js";
import dashboardStyles from "assets/jss/storefront/views/dashboardStyle.js";

import clsx from 'clsx';
import { IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles(styles);
const useDashboardStyles = makeStyles(dashboardStyles)

export default function RegularButton(props) {
  const classes = useStyles();
  const {
    color,
    round,
    children,
    disabled,
    simple,
    size,
    block,
    link,
    justIcon,
    className,
    muiClasses,
    ...rest
  } = props;
  const btnClasses = classNames({
    [classes.button]: true,
    [classes[size]]: size,
    [classes[color]]: color,
    [classes.round]: round,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [className]: className
  });
  return (
    <Button {...rest} classes={muiClasses} className={btnClasses}>
      {children}
    </Button>
  );
}

RegularButton.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "white",
    "transparent"
  ]),
  size: PropTypes.oneOf(["sm", "lg"]),
  simple: PropTypes.bool,
  round: PropTypes.bool,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  link: PropTypes.bool,
  justIcon: PropTypes.bool,
  className: PropTypes.string,
  // use this to pass the classes props from Material-UI
  muiClasses: PropTypes.object,
  children: PropTypes.node
};


export function SubmitButton(props) {

  const [submitting, setSubmitting] = React.useState(props.submitting)
  const [success, setSuccess] = React.useState(props.success)

  // Handle update submitting from parent value
  React.useEffect(() => {
    setSubmitting(props.submitting)
  }, [props.submitting])

  // Handle update success from parent value
  React.useEffect(() => {
    setSuccess(props.success)
  }, [props.success])

  const classes = useDashboardStyles();
  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  if (props.iconButton) {
    return (
      <div className={classes.buttonRoot}>
        <div className={classes.buttonWrapper}>
          <IconButton
            color='primary'
            onClick={props.onClick}
            disabled={submitting}
            type='submit'
          >
            <FontAwesomeIcon icon={props.icon} />
          </IconButton>
          { submitting && <CircularProgress size={24} className={classes.buttonProgress} /> }
        </div>
      </div>
    )
  }

  return (
    <div className={classes.buttonRoot}>
      <div className={classes.buttonWrapper} style={{ width: props.fullWidth ? '100%' : '' }}>
        <Button
          color='primary'
          variant='contained'
          className={buttonClassname}
          onClick={props.onClick}
          disabled={submitting}
          type='submit'
          {...props}
        >
          {props.buttonText}
        </Button>
        {submitting && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    </div>
  )

}