import NeedHelp from 'views/NeedHelp/NeedHelp'


const needHelpRoutes = [
    {
        path:'/',
        name: 'Need Help?',
        component: NeedHelp
    },
]

export default needHelpRoutes