import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';


const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));


function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                        id: props.id
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix="$"
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};


export function MaterialNumberFormat(props) {
    const classes = useStyles();

    return (
        <TextField
            id={props.id}
            label={props.label}
            value={props.value}
            onChange={props.onChange}
            id={props.id}
            InputProps={{
                inputComponent: NumberFormatCustom,
            }}
            required={props.required}
            variant={props.variant}
            disabled={props.disabled}
            minimum={props.minimum}
            margin={props.margin}
            style={props.style}
        />
    );

}