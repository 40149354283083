import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Guardians from 'layouts/Guardians/Guardians'
import Cart from 'layouts/Cart/Cart'
import School from 'layouts/School/School'
import Profile from 'layouts/Profile/Profile'
import NeedHelp from 'layouts/NeedHelp/NeedHelp'
import Login from './Login'
import Register from 'layouts/Register'
import SplashPage from 'views/Splash/Splash'
import * as serviceWorker from './serviceWorker';

import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";


import { checkAuth, whoAmI } from './api/auth'
import { UserProvider } from './components/UserProvider.js'
import { CartProvider } from './components/CartProvider.js'
import { SnackbarProvider } from 'notistack';

import Button from '@material-ui/core/Button';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Preloader from 'components/Preloader/Preloader'
import OnboardingPage from './views/Onboarding/Onboarding';


const hist = createBrowserHistory();


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)

class AppRoute extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            loaded: false
        };
    }

    async componentDidMount() {
        if (this.props.logInRequired) {

            if (this.props.location.state !== undefined) {
                if (this.props.location.state.preAuthed) {
                    if (this.props.guardiansOnly) {
                        if (this.props.location.state.userIsGuardian) {
                            this.setState({
                                isAuthenticated: true,
                                loaded: true,
                            })
                        }
                        else {
                            this.setState({
                                loaded: true
                            })
                        }
                    }
                    else {
                        this.setState({
                            isAuthenticated: true,
                            loaded: true,
                        })
                    }
                }
            }


            else {
                let r = await checkAuth();
                if (r) {
                    // Depreciate props.guardiansOnly
                    // if (this.props.guardiansOnly) {
                    //     let r1 = await whoAmI()
                    //     if (r1.ok && r1.data.guardian_grants.length !== 0) {
                    //         this.setState({
                    //             isAuthenticated: true,
                    //             loaded: true,
                    //         })
                    //     }
                    //     else {
                    //         this.setState({
                    //             loaded: true
                    //         })
                    //     }
                    // }
                    // else {
                    //     this.setState({
                    //         isAuthenticated: true,
                    //         loaded: true
                    //     })
                    // }
                    let r1 = await whoAmI()
                    localStorage.setItem('appUser', JSON.stringify(r1.data))
                    this.setState({
                        isAuthenticated: true,
                        loaded: true,
                    })

                } else {
                    this.setState({
                        loaded: true
                    })
                }
            }

        }
        else {
            this.setState({
                isAuthenticated: true,
                loaded: true
            })
        }
    }


    render() {
        const { component: Component, ...rest } = this.props;

        // add action to all snackbars
        const notistackRef = React.createRef();
        const onClickDismiss = key => () => {
            notistackRef.current.closeSnackbar(key);
        }

        if (!this.state.loaded) return null;
        return (
            <Route {...rest} render={props => (
                this.state.isAuthenticated ? (
                    <UserProvider>
                        <CartProvider>
                            <SnackbarProvider
                                maxSnack={3}
                                ref={notistackRef}
                                action={(key) => (
                                    <Button onClick={onClickDismiss(key)}>
                                        Dismiss
                        </Button>
                                )}
                            >
                                <Elements stripe={stripePromise}>
                                    <Component {...props} />
                                </Elements>
                            </SnackbarProvider>
                        </CartProvider>
                    </UserProvider>
                ) : (
                        <Redirect to={{
                            pathname: '/login',
                            state: { from: props.location }
                        }} />
                    )
            )} />
        );
    }
}


class LandingRedirect extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loaded: false,
            userIsGuardian: false,
            isAuthenticated: false
        }
    }

    async componentDidMount() {
        if (this.props.location.state !== undefined) {
            if (this.props.location.state.preAuth) {
                this.setState({
                    userIsGuardian: this.props.location.state.userIsGuardian,
                    loaded: true,
                    isAuthenticated: true
                })
            }
        }

        else {
            const r = await checkAuth()
            if (r) {
                const r1 = await whoAmI()
                if (r1.ok) {
                    // No user
                    if (Object.keys(r1.data).length === 0) {
                        this.setState({
                            loaded: true
                        })
                    }
                    else if (r1.data.guardian_grants.length !== 0) {
                        this.setState({
                            userIsGuardian: true,
                            loaded: true,
                            isAuthenticated: true
                        })
                    }
                    else if (r1.data.school_staff_memberships.length !==0) {
                        this.setState({
                            userIsStaff: true,
                            loaded: true,
                            isAuthenticated: true,
                        })
                    }
                    else {
                        this.setState({
                            loaded: true,
                            isAuthenticated: true
                        })
                    }
                }
                else {
                    this.setState({
                        loaded: true
                    })
                }
            }
            else {
                this.setState({
                    loaded: true
                })
            }
        }
    }

    render() {
        if (!this.state.loaded) { return <Preloader /> }

        if (!this.state.isAuthenticated) { return (
            <Redirect to={{
                pathname: '/login',
                state: { from: this.props.location }
            }} />
        ) }

        return (
            <Redirect
                from='/'
                to={{
                    pathname: (this.state.userIsGuardian || this.state.userIsStaff) ? '/guardians/dashboard' : '/',
                    state: {preAuthed: true, userIsGuardian: this.state.userIsGuardian}
                }}
            />
        )
    }
}

class SnackbarRoute extends React.Component {
    render() {
        const { component: Component, ...rest } = this.props

        // add action to all snackbars
        const notistackRef = React.createRef();
        const onClickDismiss = key => () => {
            notistackRef.current.closeSnackbar(key);
        }

        return (
            <Route {...rest} render={props => (
                <SnackbarProvider
                    maxSnack={3}
                    ref={notistackRef}
                    action={(key) => (
                        <Button onClick={onClickDismiss(key)}>
                            Dismiss
                        </Button>
                    )}
                >
                    <Component {...props} />
                </SnackbarProvider>
            )}
            />
        )
    }
}

ReactDOM.render(
    <Router history={hist}>
        <Switch>
            <AppRoute path='/guardians' component={Guardians} logInRequired />
            <AppRoute path='/profile' component={Profile} logInRequired />
            <AppRoute path='/cart' component={Cart} />
            <AppRoute path='/school' component={School} />
            <AppRoute path='/need-help' component={NeedHelp} />
            <SnackbarRoute path='/login' component={Login} />
            <SnackbarRoute path='/register' component={Register} />
            <SnackbarRoute path='/account-discover' component={OnboardingPage} />
            <AppRoute exact path='/' component={SplashPage} />
            {/* <Redirect from='/' to='/store/dashboard' /> */}
            <Redirect from='/s/*' to='/school/*' />
            <LandingRedirect />
        </Switch>
    </Router>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
