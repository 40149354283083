import React from 'react';
import BaseLayout from 'layouts/Base'
import routes from './SchoolRoutes'


function School() {
    return (
        <BaseLayout
            routes={routes}
            baseUrl='/school'
        />
    )
}

export default School