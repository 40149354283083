import { api_call, make_response_object } from 'api/base'


export async function listStudentOrderItems(schoolId, studentId, params) {
    const r = await api_call(
        '/schools/' + schoolId + '/students/' + studentId + '/order_items',
        'GET',
        {},
        params
    )
    return await make_response_object(r)
}


export async function getStudentOrderItem(schoolId, studentId, orderItemId) {
    const r = await api_call(
        '/schools/' + schoolId + '/students/' + studentId + '/order_items/' + orderItemId,
        'GET'
    )
    return await make_response_object(r)
}