import { api_call, make_response_object } from 'api/base'


export async function listStudents(schoolId, params) {
    const r = await api_call('/schools/' + schoolId + '/students', 'GET', {}, params)
    return await make_response_object(r)
}


export async function getStudent(schoolId, studentId) {
    const r = await api_call('/schools/' + schoolId + '/students/' + studentId, 'GET')
    return await make_response_object(r)
}


export async function createStudent(schoolId, body) {
    const r = await api_call(
        '/schools/' + schoolId + '/students',
        'POST',
        body
    )
    return await make_response_object(r)
}


export async function updateStudent(schoolId, studentId, body) {
    const r = await api_call(
        '/schools/' + schoolId + '/students/' + studentId,
        'PUT',
        body
        )
    return await make_response_object(r)
}


export async function listStudentOrderItems(schoolId, studentId, params) {
    const r = await api_call(
        '/schools/' + schoolId + '/students/' + studentId + '/order_items',
        'GET',
        {},
        params
    )
    return await make_response_object(r)
}