import { faCalendarTimes } from '@fortawesome/free-solid-svg-icons';
import { faCreditCard, faMoneyCheck, faTrashAlt, faHandHoldingUsd, faUtensils, faDollarSign, faBusAlt } from '@fortawesome/free-solid-svg-icons';
import { faBellSchool } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, TableContainer, ListItemText, TableCell, Paper, Table, TableBody, TableRow, IconButton, TextField, List, ListItem } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { make_filter_params } from 'api/base';
import { listSubscriptions, deactivateSubscription } from 'api/subscriptions'
import Preloader from 'components/Preloader/Preloader';
import { UserContext } from 'components/UserProvider.js';
import currency from 'currency.js';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React from 'react';
import { ControlledInputInternals } from 'components/CustomInput/CustomInput.js'
import { SubmitButton } from 'components/CustomButtons/Button.js'
import { useHistory, Link } from "react-router-dom";
import { updateUser } from '../../api/users';



export default function UserInfo(props) {
    const [currentUser, setCurrentUser] = React.useContext(UserContext)
    let history = useHistory()
    const [loading, setLoading] = React.useState(true)
    const [submitting, setSubmitting] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar()

    const [formState, dispatch, onChange] = ControlledInputInternals();

    // Inputs
    const { first_name, last_name } = formState;


    React.useEffect(() => {
        async function init() {
            dispatch({ field: 'first_name', value: currentUser.first_name})
            dispatch({ field: 'last_name', value: currentUser.last_name })
            setLoading(false)
        }
        init()
    }, [])


    async function handleUpdateUserProfile() {
        setSubmitting(true)
        const r = await updateUser(currentUser.id, {'first_name': first_name, 'last_name': last_name})
        setSubmitting(false)
        if (r.ok) {
            setSuccess(true)
            localStorage.setItem('appUser', JSON.stringify(r.data))
            enqueueSnackbar('User Profile Updated', { variant: 'success' })
        }
        else {
            setSuccess(false)
            enqueueSnackbar('An error occurred', { variant: 'error' })
        }
    }

    return(
        <>
            <Typography style={{ fontSize: 22 }}>
                User Profile
            </Typography>
            <br />
            <TextField
                id='first_name'
                label='First Name'
                variant='outlined'
                value={first_name}
                onChange={onChange}
                style={{ marginRight: '1em' }}
                required
            />
            <TextField
                id='last_name'
                label='Last Name'
                variant='outlined'
                value={last_name}
                onChange={onChange}
                required
            />
            <br/>
            <SubmitButton
                submitting={submitting}
                success={success}
                buttonText={'Update Profile'}
                onClick={handleUpdateUserProfile}
            />
            <br /><br /><br />
            <Typography style={{ fontSize: 22 }}>
                Your Students
            </Typography>
            <Typography style={{ fontSize: 13}}>
                To add/remove students from your account, contact your school or the&nbsp;
                <span
                    onClick={() => history.push('/need-help')}
                    style={{
                        cursor: 'pointer',
                        textDecoration: 'underline'
                    }}
                >
                    Charter Pay support team
                </span>
            </Typography>
            <List>
                {currentUser.students.map(s => (
                    <ListItem>
                        <ListItemText
                            primary={s.first_name + ' ' + s.last_name}
                            secondary={'Grade: '  + s.grade_level}
                        />
                    </ListItem>
                ))}
            </List>
        </>
    )

}