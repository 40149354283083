import { Box, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { createPaymentMethod } from 'api/paymentmethods';
import 'assets/css/stripeStyle.css';
import stripeLogo from 'assets/img/powered_by_stripe.png';
import { SubmitButton } from 'components/CustomButtons/Button.js';
import { ControlledInputInternals } from 'components/CustomInput/CustomInput.js';
import { UserContext } from 'components/UserProvider.js';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Link } from "react-router-dom";



export default function NewPaymentMethod(props) {
    const currentUser = React.useContext(UserContext)[0]
    const [methodType, setMethodType] = React.useState('card')
    const [error, setError] = React.useState(null)
    const [submitting, setSubmitting] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const [formState, dispatch, basicInputOnChange, onChange] = ControlledInputInternals()
    const { enqueueSnackbar } = useSnackbar();
    

    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: '#32325d',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4'
                }
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        }
    }


    async function handleSubmit(e) {
        e.preventDefault()

        setSubmitting(true)
        setSuccess(false)

        if (methodType !== 'card') {
            if (!document.getElementById('bank-account-form').reportValidity()){
                setSubmitting(false)
                return
            }
        }

        if (methodType === 'card') {
            const card = elements.getElement(CardElement)
            var stripeR = await stripe.createToken(card)
        }
        else {
            var stripeR = await stripe.createToken('bank_account', {
                country: 'US',
                currency: 'usd',
                routing_number: formState.routingNumber,
                account_number: formState.accountNumber,
                account_holder_name: formState.accountHolderName,
                account_holder_type: formState.accountType,
            })
        }
        if (stripeR.error) {
            enqueueSnackbar(stripeR.error.message, { variant: 'error' })
            setSubmitting(false)
            return
        }
        const r = await createPaymentMethod(
            currentUser.id,
            {
                type: methodType,
                token: stripeR.token.id
            }
        )
        if (!r.ok) {
            enqueueSnackbar('An error occurred', { variant: 'error' })
            setSubmitting(false)
            return
        }
        enqueueSnackbar((methodType === 'card' ? 'Card' : 'Bank Account') + ' Added!', { variant: 'success' })
        setSubmitting(false)
        setSuccess(true)
    }


    return (
        <>
            <Typography style={{fontSize: 20}}>
                Add a New Payment Method
            </Typography>
            <br />
            <FormControl component='fieldset'>
                <RadioGroup
                    row
                    value={methodType}
                    onChange={(e) => setMethodType(e.target.value)}
                >
                    <FormControlLabel
                        value='card'
                        control={<Radio color='primary' />}
                        label='Card'
                        labelPlacement='end'
                        style={{marginRight: '5em'}}
                    />
                    <FormControlLabel
                        value='bank_account'
                        control={<Radio color='primary' />}
                        label='Bank Account'
                        labelPlacement='end'
                    />
                </RadioGroup>
            </FormControl>
            <br /><br />
            {methodType === 'card' ? (
                <form id='card-form' onSubmit={handleSubmit}>
                    <FormLabel>
                        Credit or debit card
                    </FormLabel>
                    <CardElement
                        id='card-element'
                        options={CARD_ELEMENT_OPTIONS}
                        onChange={null}
                    />
                    <br /><br />
                    <Box>
                        <img src={stripeLogo} />
                        <Typography>
                            {'Your card information is stored securly by '}
                            <a href='https://stripe.com' target='_blank' rel='noopener'>Stripe</a>
                        </Typography>
                    </Box>
                    <br /><br />
                    <Grid
                        container
                        direction='row'
                        justify='flex-end'
                        alignItems='center'
                    >
                        <SubmitButton
                            submitting={submitting}
                            success={success}
                            buttonText={'Add Card'}
                            onClick={handleSubmit}
                        />
                    </Grid>
                </form>
            ) : (
                <form id='bank-account-form'>
                    <Grid container spacing={3}>
                        <Grid item>
                            <TextField
                                required
                                id='accountNumber'
                                label='Account Number'
                                type='number'
                                value={formState.accountNumber}
                                onChange={basicInputOnChange}
                                style={{ minWidth: 300 }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                required
                                id='routingNumber'
                                label='Routing Number'
                                type='number'
                                value={formState.routingNumber}
                                onChange={basicInputOnChange}
                                style={{ minWidth: 300 }}
                            />
                        </Grid>
                    </Grid>
                        <Grid container spacing={3}>
                            <Grid item>
                                <TextField
                                    required
                                    id='accountHolderName'
                                    label="Account Holder's Name"
                                    value={formState.accountHolderName}
                                    onChange={basicInputOnChange}
                                    style={{ minWidth: 300 }}
                                />
                            </Grid>
                            <Grid item>
                                <FormControl required>
                                    <InputLabel id='account-type-label'>Account Holder Type</InputLabel>
                                    <Select
                                        labelId='account-type-label'
                                        id='accountType'
                                        value={formState.accountType}
                                        onChange={(e) => onChange('accountType', e.target.value)}
                                        style={{minWidth: 300}}
                                    >
                                        <MenuItem value='individual'>Individual</MenuItem>
                                        <MenuItem value='company'>Company</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <br /><br />
                        <Typography>
                            Once you add this bank account, Stripe will make two small deposits ("microdeposits") in the bank account. These deposits take one to two (1-2) business days to appear in the account. Once you receive the desposits, you can verify your bank account by going to My Account → Payment Methods. You cannot use the bank account to make purchases until it is verified.
                        </Typography>
                        <br /><br />
                        <Box>
                            <img src={stripeLogo} />
                            <Typography>
                                {'Your bank account information is stored securly by '}
                                <Link to='https://stripe.com'>Stripe</Link>
                            </Typography>
                        </Box>
                        <br /><br />
                        <Grid
                            container
                            direction='row'
                            justify='flex-end'
                            alignItems='center'
                        >
                            <SubmitButton
                                submitting={submitting}
                                success={success}
                                buttonText={'Add Bank Account'}
                                onClick={handleSubmit}
                            />
                        </Grid>
                </form>
            )}
        </>
    )
}