import React from "react";
import AppBar from "@material-ui/core/AppBar";
import { Helmet } from 'react-helmet'
import { Grid, Typography, Toolbar, Box, Divider, Button, TextField, InputAdornment, Input, List, ListItem, ListItemText } from "@material-ui/core";
import logo from 'assets/img/csplogo.png';
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/storefront/components/navbarStyle.js";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { listSchools } from 'api/school'
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { make_filter_params } from 'api/base'
import useConstant from "use-constant";
import Preloader from 'components/Preloader/Preloader';
import { UserContext } from 'components/UserProvider.js'
import Navbar from 'components/Navbar/Navbar'



export default function SplashPage(props) {

    const [schools, setSchools] = React.useState([])
    const [schoolsLoading, setSchoolsLoading] = React.useState(false)
    const currentUser = React.useContext(UserContext)[0]
    const cUserLoggedIn = Object.keys(currentUser).length !== 0
    let cUserIsGuardian = false
    if (currentUser.hasOwnProperty('guardian_grants') && currentUser.guardian_grants.length !== 0) {
        cUserIsGuardian = true
        // window.location.replace('/guardians/dashboard')
    }
    if (currentUser.hasOwnProperty('school_staff_grants') && currentUser.school_staff_grants.length !== 0) {
        // window.location.replace('/guardians/dashboard')
    }

    
    async function searchSchools(searchString) {
        setSchoolsLoading(true)

        if (searchString === '') {
            setSchools([])
            setSchoolsLoading(false)
            return
        }

        const filters = [
            {field: 'display_name', op: 'contains', value: searchString}
        ]
        let params = make_filter_params(filters)
        params['public'] = 'True'

        const r = await listSchools(params)
        if (!r.ok) { 
            setSchoolsLoading(false)
            return
        }
        setSchools(r.data.results)

        setSchoolsLoading(false)
    }


    const searchSchoolsDebounced = useConstant(() => 
        AwesomeDebouncePromise(
            searchSchools,
            400
        )
    )


    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Charter Pay</title>
            <meta content="width=device-width, initial-scale=1" name="viewport" />
            <meta content="Webflow" name="generator" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
            <link href={logo} rel="shortcut icon" type="image/x-icon" />
            <link href={logo} rel="apple-touch-icon" />
        </Helmet>
        <div style={{ padding: cUserLoggedIn ? 0 : '3em' }}>
        {!cUserLoggedIn ?
        <AppBar
            position='static'
            style={{ background: 'transparent', 'boxShadow': 'none'}}
        >
            <Toolbar>
                <Grid container direction='row' alignItems='center'>
                    <Grid item>
                        <img src={logo} alt='Charter Pay' style={{ height: '50px' }} />
                    </Grid>
                    <Grid item>
                        <Typography
                            style={{ paddingLeft: '10px', color: '#000', 'font-style': 'normal', fontSize: 22}}
                            align='center'
                        >
                            Charter Pay
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container direction='row' justify='flex-end' spacing={3}>
                    <Grid item>
                        <Typography
                            style={{ color: '#000', fontSize: 14, textAlign: 'right'}}
                        >
                            <a
                                href='https://www.chartersuccesspartners.com/charter-pay-demo-registration'
                                style={{ textDecoration: 'none', color: '#000'}}
                            >
                                REQUEST A DEMO
                            </a>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            style={{ color: '#000', fontSize: 14, textAlign: 'right'}}
                        >
                            <a
                                href='https://www.chartersuccesspartners.com/charterpay'
                                style={{ textDecoration: 'none', color: '#000'}}
                            >
                                LEARN MORE
                            </a>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            style={{ color: '#000', fontSize: 14, textAlign: 'right' }}
                        >
                            <a
                                href='https://admin.charterpay.org'
                                style={{ textDecoration: 'none', color: '#000' }}
                            >
                                ADMIN LOGIN
                        </a>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Link to='/login' style={{textDecoration: 'none'}}>
                            <Button
                                variant='contained'
                                color='primary'
                            >
                                Login
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
        :
        <Navbar
            logoLink={cUserIsGuardian ? '/guardians/dashboard' : '/'}
        />
        }
        <div style={{padding: '.5em'}}>
        <Grid
            container
            direction='column'
            justify='flex-start'
            alignItems='center'
            style={{marginTop: '3em'}}
            spacing={1}
        >
            <Grid item>
                <Typography
                    style={{fontSize: 42, textAlign: 'center'}}
                >
                    Simple and secure payments for schools
                </Typography>
            </Grid>
            { !cUserLoggedIn &&
            <>
            <Grid item style={{marginTop: '1em'}}>
                <Typography style={{ fontSize: 26, textAlign: 'center' }}>
                    New Parent/Guardians
                </Typography>
            </Grid>
            <Grid item>
                <Button
                    variant='contained'
                    color='primary'
                    href='/account-discover'
                >
                    Set up your account
                </Button>
            </Grid>
            <Grid item style={{marginTop: '1em'}}>
                <Typography style={{ fontSize: 26, textAlign: 'center' }}>
                    Returning Users
                </Typography>
            </Grid>
            <Grid item>
                <Button
                    variant='contained'
                    color='primary'
                    href='/login'
                >
                    Login
                </Button>
            </Grid>
            </>
            }
            { //currentUser.hasOwnProperty('guardian_grants') && currentUser.guardian_grants.length !== 0 &&
            cUserLoggedIn &&
            <>
            <Grid item style={{marginTop: '5em'}}>
                <Typography style={{ fontSize: 22, textAlign: 'center'}}>
                    Are you a parent, guardian, or school staff? Go to the Guardian/Staff Portal.
                </Typography>
            </Grid>
            <Grid item>
                <Button
                    variant='contained'
                    color='primary'
                    href='/guardians/dashboard'
                >
                    Guardian/Staff Portal
                </Button>
            </Grid>
            </>
            }
                    <Grid item style={{ marginTop: '3.5em' }}>
                        <Typography style={{ fontSize: 26, textAlign: 'center'}}>
                            Member of the public?
                </Typography>
            </Grid>
            <Grid item >
                <Typography style={{ fontSize: 22}}>
                    Search for a school
                </Typography>
            </Grid>
            <Grid item>
                <Input
                    startAdornment={
                        <InputAdornment position='start'>
                            <FontAwesomeIcon icon={faSearch} />
                        </InputAdornment>
                    }
                    onChange={(e) => searchSchoolsDebounced(e.target.value)}
                />
                {schoolsLoading ?
                <Preloader
                    styles={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginTop: '1em'
                    }}
                /> :
                <List>
                    {schools.map(s => (
                        <Link
                            to={{
                                pathname: '/school/' + s.url_slug,
                                state: {
                                    school: s
                                }
                            }}
                            style={{textDecoration: 'none', color: '#000'}}
                        >
                        <ListItem
                            button
                        >
                            <ListItemText primary={s.display_name} />
                        </ListItem>
                        </Link>
                    ))}
                </List>
                }
            </Grid>
        </Grid> 
        </div>
        </div>
        </>
    )
}