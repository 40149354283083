import { faCreditCard, faMoneyCheck, faTrashAlt, faCheckCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, TextField } from '@material-ui/core';
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { deletePaymentMethod, listPaymentMethods, verifyBankAccount } from 'api/paymentmethods';
import Preloader from 'components/Preloader/Preloader';
import { UserContext } from 'components/UserProvider.js';
import React from 'react';
import { Link } from "react-router-dom";
import { MaterialNumberFormat } from 'components/CustomInput/NumberFormat.js'
import { ControlledInputInternals } from 'components/CustomInput/CustomInput.js'
import { useSnackbar } from 'notistack';



export default function PaymentMethodsView() {
    const currentUser = React.useContext(UserContext)[0]
    const [paymentMethods, setPaymentMethods] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [dialogOpen, setDialogOpen] = React.useState(false)
    const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState(null)
    const [selectedBankAccount, setSelectedBankAccount] = React.useState(null)
    const [verifyDialogOpen, setVerifyDialogOpen] = React.useState(false)
    const [dialogText, setDialogText] = React.useState('')
    const [verifying, setVerifying] = React.useState(false)
    const [verifyError, setVerifyError] = React.useState(null)

    const [formState, dispatch, basicInputOnChange, onChange] = ControlledInputInternals()
    const { enqueueSnackbar } = useSnackbar();


    React.useEffect(() => {
        async function init() {
            const r = await listPaymentMethods(currentUser.id)
            if (r.ok) {
                setPaymentMethods(r.data.results)
            }
            setLoading(false)
        }

        init()
    }, [])


    React.useEffect(() => {
        if (selectedPaymentMethod === null) { return }
        console.log(selectedPaymentMethod.method_type)

        let text = ''
        if (selectedPaymentMethod.method_type === 'card') {
            text = selectedPaymentMethod.card_brand.charAt(0).toUpperCase() + selectedPaymentMethod.card_brand.substring(1) + ' ending in '
        }
        else {
            text = selectedPaymentMethod.bank_name + ' account ending in '
        }
        console.log(text)
        setDialogText(
            'Delete ' + text + selectedPaymentMethod.last_four + '?'
        )
    }, [selectedPaymentMethod])


    function handleMethodTrash(method) {
        setSelectedPaymentMethod(method)
        setDialogOpen(true)
        
    }


    function handleBankAccountVerify(method) {
        setSelectedBankAccount(method)
        setVerifyDialogOpen(true)
    }


    async function handleDialogReponse(del) {
        if (del) {
            setDialogText(<center><Preloader noStyle /></center>)
            const r = await deletePaymentMethod(currentUser.id, selectedPaymentMethod.id)
            if (r.ok) {
                let pM = paymentMethods.slice()
                pM.splice(
                    pM.indexOf(
                        pM.find(m => m.id === selectedPaymentMethod.id)
                    ),
                    1
                )
                setPaymentMethods(pM)
            }
        }
        setDialogOpen(false)
        setSelectedPaymentMethod(null)
    }


    async function handleVerifyDialogResponse() {
        if (formState.microDep1 === undefined || formState.microDep2 === undefined) { return }
        
        setVerifying(true)
        setVerifyError(null)

        const r = await verifyBankAccount(
            currentUser.id,
            selectedBankAccount.id,
            formState
        )
        if (!r.ok) {
            setVerifyError('An error occurred.')
            setVerifying(false)
        }
        else {
            let pM = paymentMethods.slice()
            pM.splice(
                pM.indexOf(
                    pM.find(m => m.id === selectedBankAccount.id)
                ),
                1,
                r.data
            )
            setPaymentMethods(pM)
            setVerifying(false)
            setVerifyDialogOpen(false)
            setSelectedBankAccount(null)
            enqueueSnackbar('Verification Successful.', { variant: 'success' })
        }
    }


    if (loading) { return <Preloader /> }

    return (
        <>
            <Grid container spacing={10}>
                <Grid item>
                    <Box borderRight={1}>
                        <Typography style={{fontSize: 20}}>
                            Saved Payment Methods
                        </Typography>
                        <List style={{width: '600px'}}>
                            {paymentMethods.map(m => (
                                <ListItem key={m.id}>
                                    <ListItemIcon>
                                        <FontAwesomeIcon
                                            icon={m.method_type === 'card' ? faCreditCard : faMoneyCheck}
                                            color={m.method_type === 'card' ? '#253c96' : '#259646'}
                                            size='2x'
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            (m.method_type === 'card'
                                                ? m.card_brand.charAt(0).toUpperCase() + m.card_brand.substring(1) + ' ending in '
                                                : m.bank_name + ' account ending in ')
                                            + m.last_four
                                        }
                                        secondary={m.method_type === 'bank_account' && 
                                            (m.status === 'verified' ?
                                            <>
                                                <FontAwesomeIcon
                                                    icon={faCheckCircle}
                                                    color='#259646'
                                                />
                                                <Typography component='span' style={{marginLeft: '3px'}}>Verified</Typography>
                                            </> :
                                            <>
                                                <FontAwesomeIcon
                                                    icon={faQuestionCircle}
                                                    color='grey'
                                                />
                                                <Typography component='span' style={{ marginLeft: '3px' }}>Unverified</Typography>
                                            </>
                                            )
                                        }
                                        style={{marginRight: '1em'}}
                                    />
                                    <ListItemSecondaryAction>
                                        {m.method_type === 'bank_account' && m.status !== 'verified' &&
                                            <Button
                                                color='primary'
                                                onClick={() => handleBankAccountVerify(m)}
                                            >
                                                Verify
                                            </Button>                                      
                                        }
                                        <IconButton onClick={() => handleMethodTrash(m)}>
                                            <FontAwesomeIcon
                                                icon={faTrashAlt}
                                            />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Grid>
                <Grid item>
                    <br /><br />
                    <Link to='payment-methods/new'>
                        <Button variant='contained' color='primary'>
                            Add a Payment Method
                        </Button>
                    </Link>
                </Grid>
            </Grid>
            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
            >
                <DialogTitle>
                    Delete Payment Method?
                </DialogTitle>
                <DialogContent>
                {selectedPaymentMethod === null ? null : 
                    <DialogContentText>
                        {dialogText}
                    </DialogContentText>
                }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDialogReponse(true) }>Yes</Button>
                    <Button onClick={() => handleDialogReponse(false)}>No</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={verifyDialogOpen}
                onClose={() => setVerifyDialogOpen(false)}
            >
                <DialogTitle>
                    Verify Bank Account
                </DialogTitle>
                <DialogContent>
                    {selectedBankAccount === null ? null :
                    <>
                        <DialogContentText>
                            Please enter the amounts of the two microdeposits.
                        </DialogContentText>
                        <MaterialNumberFormat
                            id='microDep1'
                            autoFocus
                            margin='dense'
                            variant='outlined'
                            style={{marginRight: '1em'}}
                            value={formState.microDep1}
                            onChange={basicInputOnChange}
                        />
                        <MaterialNumberFormat
                            id='microDep2'
                            margin='dense'
                            variant='outlined'
                            value={formState.microDep2}
                            onChange={basicInputOnChange}
                        />
                        {verifying &&
                            <center>
                                <Preloader noStyle/>
                            </center>
                        }
                        {verifyError !== null &&
                            <Typography style={{color: 'red'}}>
                                {verifyError}
                            </Typography>
                        }
                    </>
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={verifying}
                        onClick={() => handleVerifyDialogResponse()}
                        color='primary'
                    >
                        Verify
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}