import { faCreditCard, faFileInvoiceDollar, faCalendarAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import Grid from '@material-ui/core/Grid';
import CardButton from 'components/CardButton/CardButton';
import React from 'react';



export default function Profile(props) {

    return(
        <Grid container spacing={5}>
            <Grid item>
                <CardButton
                    linkTo='/profile/user-info'
                    icon={faUser}
                    text='Profile'
                />
            </Grid>
            <Grid item>
                <CardButton
                    linkTo='/profile/payment-methods'
                    icon={faCreditCard}
                    text='Payment Methods'
                />
            </Grid>
            <Grid item>
                <CardButton
                    linkTo='/profile/transactions'
                    icon={faFileInvoiceDollar}
                    text='Transactions'
                />
            </Grid>
            <Grid item>
                <CardButton
                    linkTo='/profile/subscriptions'
                    icon={faCalendarAlt}
                    text='Subscriptions'
                />
            </Grid>
        </Grid>
    )
}