import React from 'react';
import BaseLayout from 'layouts/Base'
import routes from './GuardiansRoutes'


function Guardians() {
    return (
        <BaseLayout
            routes={routes}
            baseUrl='/guardians'
        />
    )
}

export default Guardians;
