import React from 'react';
import styles from "assets/jss/storefront/views/dashboardStyle.js";
import { makeStyles } from '@material-ui/core/styles';
import StoreItemList from 'components/StoreItemList/StoreItemList'

const useStyles = makeStyles(styles);


export default function ActivitiesPage() {
    const classes = useStyles()


    return (
        <StoreItemList
            category='activities'
            catSingular='Activity / Field Trip'
            catPlural='Activities / Field Trips'
            openSignupOnly
            openActivitiesOnly
        />
    )
}