import CartView from 'views/Cart/Cart'
import TransactionsView from 'views/Profile/Transactions'


const cartRoutes = [
    {
        path: '/',
        name: 'Cart',
        component: CartView,
    },
    {
        path: '/order',
        name: 'Order',
        component: TransactionsView,
    }
]

export default cartRoutes