import { api_call, make_response_object } from 'api/base'


export async function listPaymentMethods(userId, params) {
    const r = await api_call('/users/' + userId + '/payment_methods', 'GET', {}, params)
    return await make_response_object(r)
}


export async function getPaymentMethod(userId, paymentMethodId) {
    const r = await api_call('/users/' + userId + '/payment_methods/' + paymentMethodId, 'GET')
    return await make_response_object(r)
}


export async function createPaymentMethod(userId, body) {
    const r = await api_call(
        '/users/' + userId + '/payment_methods',
        'POST',
        body
    )
    return await make_response_object(r)
}


export async function verifyBankAccount(userId, paymentMethodId, body) {
    const r = await api_call(
        '/users/' + userId + '/payment_methods/' + paymentMethodId,
        'PATCH',
        body
    )
    return await make_response_object(r)
}


export async function deletePaymentMethod(userId, paymentMethodId) {
    const r = await api_call(
        '/users/' + userId + '/payment_methods/' + paymentMethodId,
        'DELETE'
    )
    return await make_response_object(r)
}