import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { login, recover, getUserFromPasswordResetToken, resetPassword } from 'api/auth'
import { SubmitButton } from 'components/CustomButtons/Button.js'
import { useSnackbar } from 'notistack';
import { UserContext } from 'components/UserProvider.js'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import PasswordStrengthField from 'components/CustomInput/PasswordStrengthField'
import { ControlledInputInternals } from 'components/CustomInput/CustomInput';
import { discover } from '../../api/auth';
import NeedHelp from '../NeedHelp/NeedHelp';



function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <a href='https://www.chartersuccesspartners.com/'>
        Charter Success Partners
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://admin.charterpay.org/logo.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '50%',
    backgroundPosition: 'center',
    backgroundColor: 'rgba(0, 26, 51,1)'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));



export default function Login(props) {
  const classes = useStyles();
  const [user, setUser] = React.useContext(UserContext)
  const [submitting, setSubmitting] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(null)
  const [pwResetUser, setPWResetUser] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const [view, setView] = React.useState('discover')
  const [email, setEmail] = React.useState('')

  const { enqueueSnackbar } = useSnackbar();

  const [formState, dispatch, onChange] = ControlledInputInternals();


  async function handleAccountDiscover(e) {
      e.preventDefault()
      const r = await discover({
          email: email
      })
      if (r.status === 404) {
          setView('contact-support')
      }
      else if (r.status === 400) {
          setView('reset-password')
      }
      else if (r.status === 200) {
          setView('discover-success')
      }
  }



  return (
    <>
    <Grid container  component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
        {view === 'discover' && 
        <>
        <Avatar className={classes.avatar}>
                <FontAwesomeIcon icon={faLock} />
              </Avatar>
              <Typography component="h1" variant="h5">
                Find Your Account
          </Typography>
          <Typography>
          Not sure if you have set up an account? Type in your email below to see if your school has created an account for you.
          </Typography>
            <form className={classes.form}>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <SubmitButton
                submitting={submitting}
                success={success}
                buttonText='Find my Account'
                fullWidth
                onClick={handleAccountDiscover}
            />
        </form>
        </>
        }
        {view === 'contact-support' &&
        <>
        <Typography style={{marginBottom: '1em', fontSize: 24, textAlign: 'center'}}>
            Your account was not found for {email}. Please send your child's name, grade, and school to our support team below:
        </Typography>
        <NeedHelp />
        </>
        }
        {view === 'reset-password' &&
        <Typography style={{marginBottom: '1em', fontSize: 24, textAlign: 'center'}}>
            <span>Your account for {email} is already set up. Please </span>
            <Link to='/login'>Login</Link>
            <span> or </span>
             <Link to='/login/recovery'>Reset Your Password</Link>
        </Typography>
        }
        {view === 'discover-success' &&
        <>
        <Typography style={{marginBottom: '1em', fontSize: 24, textAlign: 'center'}}>
            <span>Your account for {email} was found! Please look for an email from <strong>no-reply@mail.charterpay.org </strong> 
             with subject <strong>Welcome to Charter Pay</strong>. It should arrive within 5 minutes. If you can't find it, please check your spam.
            If you still cannot find it, please contact support:</span>
        </Typography>
        <NeedHelp />
        </>
        }
        </div>
      </Grid>
    </Grid>
    </>
  );
}