import React from 'react'
import $ from 'jquery'
import 'assets/css/styles.css';
import styles from "assets/jss/storefront/views/dashboardStyle.js";
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Grid, IconButton, Paper, List, ListItemText, ListItem, ListItemSecondaryAction, Avatar, FormGroup, Button } from "@material-ui/core";
import { getStoreItem } from 'api/storeitems'
import { listOrderItems } from 'api/orderitems'
import { listStoreGroups } from 'api/storegroups'
import { listStudentOrderItems } from 'api/studentorderitems'
import { listStoreItems } from 'api/storeitems'
import { make_filter_params } from 'api/base';
import { MaterialNumberFormat } from 'components/CustomInput/NumberFormat.js'
import { ControlledInputInternals } from 'components/CustomInput/CustomInput.js'
import { SubmitButton } from 'components/CustomButtons/Button.js'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { UserContext } from 'components/UserProvider.js'
import { CartContext } from 'components/CartProvider.js'
import Switch from '@material-ui/core/Switch'
import { useSnackbar } from 'notistack';
import Preloader from 'components/Preloader/Preloader'
import DayPicker, { DateUtils } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { getStudent } from 'api/students'
import { getSchoolLunchesSettings } from 'api/schoollunchessettings'
import { listStaffOrderItems } from 'api/staff'
import currency from 'currency.js';
import { Link } from 'react-router-dom';
import { getSchool } from '../../api/school';
window.currency = currency


export default function LunchesView(props) {
    const currentUser = React.useContext(UserContext)[0]
    const [currentCart, addToCart, removeFromCart] = React.useContext(CartContext)
    const [loading, setLoading] = React.useState(true)
    const [storeItem, setStoreItem] = React.useState({})
    const schoolId = props.location.pathname.split('/')[2]
    const itemId = props.location.pathname.split('/')[4]
    const userFormRef = React.createRef()
    const permissionFormRef = React.createRef()
    const chaperoneFormRef = React.createRef()
    const driverFormRef = React.createRef()
    const studentSelectForm = React.createRef()
    const costForm = React.createRef()
    const costField = React.createRef()
    const [submitting, setSubmitting] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [allowMore, setAllowMore] = React.useState(false);
    const [needsStudentSelection, setNeedsStudentSelection] = React.useState(false)
    const [availStudents, setAvailStudents] = React.useState([])
    const [student, setStudent] = React.useState({})
    const [selectedDay, setSelectedDay] = React.useState(undefined)
    const [studentLoading, setStudentLoading] = React.useState(false)
    const [vendorList, setVendorList] = React.useState([])
    const [vendor, setVendor] = React.useState({})
    const [purchasedLunchItems, setPurchasedLunchItems] = React.useState([])
    const [lunchItemsList, setLunchItemsList] = React.useState([])
    const [availableVendors, setAvailableVendors] = React.useState([])
    const [availableLunchItems, setAvailableLunchItems] = React.useState([])
    const [dayPurchasedLunchItems, setDayPurchasedLunchItems] = React.useState([])
    const [buyForSelf, setBuyForSelf] = React.useState(false)
    const [availSchools, setAvailSchools] = React.useState([])
    const [selectedSchool, setSelectedSchool] = React.useState({})
    const [donation, setDonation] = React.useState(null)

    const { enqueueSnackbar } = useSnackbar();

    const [formState, dispatch, basicInputOnChange, onChange] = ControlledInputInternals()


    React.useEffect(() => {
        async function initPage() {
            setLoading(false)
        }
        
        initPage()
    }, [])


    React.useEffect(() => {
        if (!buyForSelf) { return }
        
    }, [buyForSelf])


    function handleSetBuyForSelf(e) {
        if (e.target.checked) {
            setStudent({})

            let aS = []
            currentUser.school_staff_memberships.map(m => {
                aS.push(m.school)
            })
            setAvailSchools(aS)

                setSelectedSchool(aS[0])
                handleSchoolChange({id: aS[0].id})
        }
        else {
            setStudent({})
            setDonation(null)
        }
        setBuyForSelf(e.target.checked)
    }


    function bankersRound(n, d = 2) {
        var x = n * Math.pow(10, d);
        var r = Math.round(x);
        var br = Math.abs(x) % 1 === 0.5 ? (r % 2 === 0 ? r : r - 1) : r;
        return br / Math.pow(10, d);
    }


    async function doListVendors(school_id) {
        // List Vendors
        let filters = []
        filters.push({ field: 'category_type', op: 'eq', value: 'lunches' })
        const params = make_filter_params(filters)
        const r = await listStoreGroups(school_id, params, enqueueSnackbar)
        if (!r.ok) { return }
        // Convert date strings to moments
        const vendors = r.data.results.map(v => {
            if (v.lunches_dates) {
                const days = v.lunches_dates.map(d => {
                    return new moment(d)
                })
                let vendor = { ...v }
                vendor.lunches_dates = days
                return vendor
            }
        })

        setVendorList(vendors.filter(v => v !== undefined))
    }


    async function doListLunchItems(school_id) {
        // List Lunch Items
        let filters2 = []
        filters2.push({ field: 'category_type', op: 'eq', value: 'lunches' })
        const params2 = make_filter_params(filters2)
        const r2 = await listStoreItems(school_id, params2)
        if (!r2.ok) {
            enqueueSnackbar('An error occurred', { variant: 'error' })
            return
        }

        return r2.data.results
    }


    async function handleSchoolChange(s) {
        setStudentLoading(true)

        setStudent(currentUser)

        await doListVendors(s.id)

        // List Purchased Lunches
        let filters = []
        filters.push({ field: 'category_type', op: 'eq', value: 'lunches' })
        const params = make_filter_params(filters)
        const r = await listStaffOrderItems(selectedSchool.id, currentUser.id, params)
        if (!r.ok) {
            enqueueSnackbar('An error occurred', { variant: 'error' })
            return
        }
        setPurchasedLunchItems(r.data.results)

        // List Lunch Items
        setLunchItemsList(await doListLunchItems(s.id))

        const r1 = await getSchoolLunchesSettings(s.id)
        if (r1.data.donation_id) {
            setDonation(r1.data.donation)
        } 

        setStudentLoading(false)
    }


    async function handleStudentChange(e) {
        setStudentLoading(true)

        let stu = currentUser.students.find(s => s.id === e.target.value)
        const r3 = await getStudent(stu.school_id, stu.id)
        if (!r3.ok) { return }
        stu = r3.data
        setStudent(stu)
        // const r5 = getSchool(stu.school_id)
        // setSelectedSchool(r5.data)

        // List Vendors
        // let filters = []
        // filters.push({field: 'category_type', op: 'eq', value: 'lunches'})
        // const params = make_filter_params(filters)
        // const r = await listStoreGroups(stu.school_id, params, enqueueSnackbar)
        // if (!r.ok) { return }
        // // Convert date strings to moments
        // const vendors = r.data.results.map(v => {
        //     const days = v.lunches_dates.map(d => {
        //         return new moment(d)
        //     })
        //     let vendor = {...v}
        //     vendor.lunches_dates = days
        //     return vendor
        // })
        // setVendorList(vendors)
        await doListVendors(stu.school_id)

        // List Purchased Lunches
        let filters1 = []
        filters1.push({field: 'category_type', op: 'eq', value: 'lunches'})
        const params1 = make_filter_params(filters1)
        const r1 = await listStudentOrderItems(stu.school_id, stu.id, params1)
        if (!r1.ok) {
            enqueueSnackbar('An error occurred', {variant: 'error'})
            return
        }
        setPurchasedLunchItems(r1.data.results)


        // List Lunch Items
        // let filters2 = []
        // filters2.push({ field: 'category_type', op: 'eq', value: 'lunches' })
        // const params2 = make_filter_params(filters2)
        // const r2 = await listStoreItems(stu.school_id, params2)
        // if (!r2.ok) {
        //     enqueueSnackbar('An error occurred', { variant: 'error' })
        //     return
        // }


        // let items = r2.data.results

        let items = await doListLunchItems(stu.school_id)

            const r4 = await getSchoolLunchesSettings(stu.school_id)
            if (r4.ok) {
                const settings = r4.data
                if (settings.donation_id) {
                    setDonation(settings.donation)
                } 
                if (stu.nslp_status !== 'none' && stu.nslp_status !== null) {

                if (settings.nslp_enabled) {

                    function handleFlatRate(item) {
                        item.cost = settings.flat_rate_all
                    }

                    function handlePercentage(item) {
                        item.cost = currency(bankersRound(currency(item.cost) * (1 - (currency(settings.percentage_all) / 100)))).format()
                    }

                    function handleFlatRatePerStudent(item) {
                        if (!stu.nslp_reduced_flat_rate) {
                            return
                        }
                        item.cost = stu.nslp_reduced_flat_rate
                    }

                    function handlePercentagePerStudent(item) {
                        if (!stu.nslp_reduced_percentage) {
                            return
                        }
                        item.cost = currency(bankersRound(currency(item.cost) * (1 - (currency(stu.nslp_reduced_percentage)) / 100))).format()
                    }

                    let handleCost = null

                    switch(settings.reduced_setting) {
                        case 'flat_rate':
                            handleCost = handleFlatRate
                            break
                        case 'percentage':
                            handleCost = handlePercentage
                            break
                        case 'flat_rate_per_student':
                            handleCost = handleFlatRatePerStudent
                            break
                        case 'percentage_per_student':
                            handleCost = handlePercentagePerStudent
                            break
                    }


                    items.forEach(item => {
                        const itemElig = (
                            settings.item_eligibility === 'any' ||
                            (
                                settings.item_eligibility === 'specific' &&
                                item.lunches_fields.nslp_eligible
                            )
                        )
                        if (itemElig) {
                            if (stu.nslp_status === 'free') {
                                item.cost = '0.00'
                            }
                                
                            else if (stu.nslp_status === 'reduced') {
                                handleCost(item)
                            }
                        }  
                    })         
                }
            }
        }
        
        setLunchItemsList(items)
        
        setStudentLoading(false)
    }


    function handleVendorChange(e) {
        if (e.target.value === 0) {
            setAvailableLunchItems([])
            setVendor({id: 0})
            return
        } 
        // Filter lunch items list by vendor
        const aLI = lunchItemsList.filter(l => l.group_id === e.target.value)
        setAvailableLunchItems(aLI)

        const ven = vendorList.find(v => v.id === e.target.value)
        setVendor(ven)
    }


    function handleDayClick(day, { selected }) {
        setAvailableLunchItems([])
        setVendor({id: 0})
        const dayMoment = moment(day).set('hour', 0)
        const today = new moment()
        console.log(day, dayMoment, today)

        // Filter vendor list by day
        const aV = vendorList.filter(v => {
            const date = v.lunches_dates.find(d => {
                // return dayMoment.isSame(d, 'day')
                return datesAreOnSameDay(new Date(day), new Date(d))
            })
            console.log(dayMoment.diff(today, 'days', true))
            if (date !== undefined && dayMoment.diff(today, 'days', true) >= v.lunches_order_cutoff_days) {
                return v
            }
        })
        setAvailableVendors(aV)
        setAvailableLunchItems([])
        setSelectedDay(selected ? undefined : day)

        // If day is deselected, clear purcahsed items list
        if (selected) {
            setDayPurchasedLunchItems([])
        }
        // Filter purchased lunch items list by day
        else {
            // const dPLI = purchasedLunchItems.filter(l => dayMoment.isSame(l.lunch_date, 'day'))
            const dPLI = purchasedLunchItems.filter(l => datesAreOnSameDay(new Date(day), new Date(l.lunch_date.replace(/-/g, "/"))))
            setDayPurchasedLunchItems(dPLI)
        }
    }


    function handleAddToCart(item) {
        const lunch_date_moment = new moment(selectedDay)
        const cartItem = {
            store_item: item,
            cost: item.cost,
            student: student,
            lunch_date: lunch_date_moment.format('YYYY-MM-DD'),
            lunch_date_cart_view: lunch_date_moment.format('MMM Do')
        }
        if (buyForSelf) {
            cartItem.staffPurchase = true
        }
        addToCart(cartItem)
    }


    function datesAreOnSameDay(first, second) {
        return(
            first.getFullYear() === second.getFullYear() &&
            first.getMonth() === second.getMonth() &&
            first.getDate() === second.getDate()
        )
        
    }
    


    const dayHasPurchasedItemsStyle = `.DayPicker-Day--highlighted {
    background-color: #259646;
    color: white;
    }`

    function dayHasPurchasedItems(day) {
        if (purchasedLunchItems.length === 0) { return false }
        const foundDay = purchasedLunchItems.find(item => {
            // return (new moment(new moment(day).format('LL'))).isSame(item.lunch_date, 'day')
            return datesAreOnSameDay(new Date(day), new Date(item.lunch_date.replace(/-/g, "/")))
        })
        // console.log(day, foundDay !== undefined)
        return foundDay !== undefined
    }


    if (loading) { return <Preloader />}

    return (
        <>
        <Typography style={{fontSize: 32, marginBottom: '1em'}}>
            Lunches
        </Typography>
        <Grid container spacing={5}>
        {currentUser.school_staff_memberships.length > 0 &&
        <Grid item>
            <FormGroup row>
                <FormControlLabel
                control={
                    <Switch
                        checked={buyForSelf}
                        onChange={handleSetBuyForSelf}
                        color='primary'
                    />
                }
                label='Buy lunch for myself instead'
                />
            </FormGroup>
        </Grid>
        }
        {donation &&
                <Grid item>
                    <Link to={'/school/' + donation.school_id + '/item/' + donation.id}>
                        <Button
                            variant='contained'
                            color='secondary'
                            >
                                Donate lunch to families in need
        </Button>
                        </Link>
                    </Grid>
            }

        </Grid>
        {!buyForSelf &&
        <>
        <FormControl style={{ marginLeft: '2em' }}>
            <InputLabel id='student-select-label'>Select a student</InputLabel>
            <Select
                labelId='student-select-label'
                id='student-select'
                value={student.id}
                onChange={handleStudentChange}
                style={{ minWidth: '200px', }}
            >
                {currentUser.students.map(s => (
                    <MenuItem value={s.id}>{s.first_name} {s.last_name}</MenuItem>
                ))}
            </Select>
        </FormControl>
        <br /><br /><br />
        </>
        }
        {buyForSelf &&
                <>
                    <FormControl style={{ marginLeft: '2em' }}>
                        <InputLabel id='school-select-label'>Select a school</InputLabel>
                        <Select
                            labelId='school-select-label'
                            id='school-select'
                            value={selectedSchool.id}
                            onChange={null}
                            style={{ minWidth: '200px', }}
                        >
                            {availSchools.map(s => (
                                <MenuItem value={s.id}>{s.display_name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <br /><br /><br />
                </>
        }
        {studentLoading ? <Preloader /> :
            (student.id === undefined ? null :
            <Grid container spacing={5}>
                <Grid item style={{ marginLeft: '2em' }}>
                    <Typography style={{fontSize: 22}}>
                        Date
                    </Typography>
                    <div>
                        <style>{dayHasPurchasedItemsStyle}</style>
                        <DayPicker
                            selectedDays={selectedDay}
                            onDayClick={handleDayClick}
                            formatDate={formatDate}
                            parseDate={parseDate}
                            modifiers={ {highlighted: dayHasPurchasedItems} }
                            disabledDays={
                                {
                                    before: new Date()
                                }
                            }
                        />
                    </div>
                    <br />
                    <Grid container spacing={1}>
                        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar
                                style={{
                                    backgroundColor: '#259646',
                                    color: 'white',
                                    height: '33px',
                                    width: '33px',
                                }}
                            >
                                1
                            </Avatar>
                        </Grid>
                        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography style={{display: 'flex'}}>
                                - Dates with purchased lunches
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item style={{marginLeft: '2em'}}>
                    <Typography style={{  fontSize: 22 }}>
                        Purchased Lunches
                    </Typography>
                    {selectedDay === undefined ?
                    <Typography>
                        Please select a date to the left.
                    </Typography>
                    :
                    dayPurchasedLunchItems.length === 0 ?
                        <Typography>
                            No purchased lunches for {student.first_name} on this day.
                        </Typography>
                        :
                        <List>
                            {dayPurchasedLunchItems.map(l => (
                                <ListItem>
                                    <ListItemText
                                        primary={l.store_item_permanent.display_name}
                                        secondary={l.lunch_vendor_permanent.display_name}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    }
                </Grid>
                <Grid item style={{ marginLeft: '2em' }}>
                    <Typography style={{ fontSize: 22 }}>
                        Purchase New Lunch
                    </Typography>
                    {selectedDay === undefined ?
                    <Typography>
                        Please select a date to the left.
                    </Typography>
                    :
                    availableVendors.length === 0 ?
                    <Typography>
                        No lunch vendors are available on this day for {student.first_name}.
                    </Typography>
                    :
                    <>
                    <FormControl>
                        <InputLabel id='vendor-select-label'>Select a Vendor</InputLabel>
                        <Select
                            labelId='vendor-select-label'
                            id='vendor-select'
                            value={vendor.id}
                            onChange={handleVendorChange}
                            style={{ minWidth: '200px' }}
                        >
                            <MenuItem value={0}></MenuItem>
                            {availableVendors.map(v => (
                                <MenuItem value={v.id}>{v.display_name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <br /><br />
                    <Box>
                        <List>
                            {availableLunchItems.map(l => (
                                <ListItem>
                                    <ListItemText
                                        primary={l.display_name + ' - $' + l.cost}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            edge='end'
                                            color='inherit'
                                            onClick={() => handleAddToCart(l)}
                                        >
                                            <FontAwesomeIcon
                                                icon={faCartPlus}
                                            />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                    </>
                    }
                </Grid>
                <Grid item style={{ marginLeft: '2em'}}>
                    <Typography style={{ fontSize: 22 }}>
                        Lunches In Cart
                    </Typography>
                    <List>
                        {currentCart.map((item, i) => (
                            ((item.store_item.category_type === 'lunches' && item.student.id === student.id) &&
                                <ListItem>
                                    <ListItemText
                                        primary={item.store_item.display_name + ' - ' + item.lunch_date_cart_view}
                                        secondary={item.store_item.group.display_name}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton edge='end' onClick={() => removeFromCart(i)}>
                                            <FontAwesomeIcon
                                                icon={faTrashAlt}
                                            />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )
                        ))}
                    </List>
                </Grid>
            </Grid>
            )
        }
        </>
    )
}