import React from 'react';
import styles from "assets/jss/storefront/views/dashboardStyle.js";
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Grid, IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from "@material-ui/core/Button";
import { Link } from 'react-router-dom';

const useStyles = makeStyles(styles);


export default function CardButton(props) {
    const classes = useStyles();

    return (
        <Link to={props.linkTo} style={{ textDecoration: 'none'}}>
            <Card className={classes.dashCard} raised>
                <CardContent>
                    <Grid
                        container
                        direction='column'
                        justify='center'
                        alignItems='center'
                        spacing={1}
                    >
                        <Grid item>
                            <FontAwesomeIcon
                                icon={props.icon}
                                size='3x'
                                color='#3F56B0'
                            />
                        </Grid>
                        <Grid item>
                            <Typography style={{ color: '#3F56B0' }}>{props.text}</Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Link>
    )
}