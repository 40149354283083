import React from 'react'
import loader from 'assets/img/loader-metronome-0.8-speed.gif'


export default function Preloader(props) {
    const styles = {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '10em'
    }

    if (props.noStyle) {
        return (<img src={loader} />)
    }

    if (props.styles) {
        return (<img src={loader} style={props.styles} />)
    }

    return (<img src={loader} style={styles}/>)
}