import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";



// ControlledInputInternals returns components for creating controlled inputs using useReducer
export function ControlledInputInternals() {
  function reducer(state, { field, value }) {
    return {
        ...state,
        [field]: value
    }
  }
  
  const [formState, dispatch] = React.useReducer(reducer, {});
  
  const onChange = (field, value) => {
    dispatch({ field: field, value: value })
  }

  //   // For React Date (Airbnb published)
  //   // We expect:
  //   // e is startDate, f is startDateId
  //   // g is endDate, h is endDateId
  //   if (e.hasOwnProperty('_isAMomentObject')) {
  //     if (e._isAMomentObject){
  //       dispatch({ field: f, value: e })
  //       dispatch({ field: h, value: g })
  //     }
  //   }

  //   // For Checkboxes
  //   else if (e.target.hasOwnProperty('checked')) {
  //     dispatch({ field: e.target.id, value: e.target.checked })
  //   }
  //   // For other input types
  //   else {
  //     dispatch({ field: e.target.id, value: e.target.value })
  //   }

  // Expects an event
  const basicInputOnChange = (e) => {
    if (e.target.hasOwnProperty('checked')) {
      onChange(e.target.id, e.target.checked)
    }
    else {
      onChange(e.target.id, e.target.value)
    }
    
  }
  

  return [formState, dispatch, basicInputOnChange, onChange]
}