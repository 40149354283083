import React from 'react';


export const UserContext = React.createContext(['', () => {}])

export const UserProvider = (props) => {
    const [user, setUser] = React.useState({})

    var cUser = localStorage.getItem('appUser')
    if (cUser == null) {
        cUser = {};
    }
    else {
        cUser = JSON.parse(cUser)
    }


    function userIsSet() {
        if (cUser.email === undefined) {
            return false
        }
        return true
    }

    return (
        <UserContext.Provider value={[cUser, setUser, userIsSet]}>
            {props.children}
        </UserContext.Provider>
    )
}
