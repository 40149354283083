import React from 'react';
import { Paper, Box } from '@material-ui/core'
import styles from "assets/jss/storefront/views/dashboardStyle.js";
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { UserContext } from 'components/UserProvider.js'
import { make_filter_params } from 'api/base'
import { listStoreItems } from 'api/storeitems'
import { useHistory } from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import useConstant from 'use-constant'
import * as moment from 'moment';
import Preloader from 'components/Preloader/Preloader';

const useStyles = makeStyles(styles);


export default function StoreItemList(props) {
    const classes = useStyles()
    const currentUser = React.useContext(UserContext)[0]
    const [storeItems, setStoreItems] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    let history = useHistory();


    async function doListStoreItems(display_name_search_value) {

        let filters = []
        filters.push({ field: 'category_type', op: 'eq', value: props.category })
        filters.push({ field: 'approval_status', op: 'eq', value: 'approved' })
        if (props.openItemsOnly) {
            filters.push({ field: 'open_items_only', op: 'eq', value: true })
        }
        if (props.openSignupOnly) {
            filters.push({ field: 'open_signup', op: 'eq', value: true })
        }
        if (props.openActivitiesOnly) {
            filters.push({ field: 'open_activities_only', op: 'eq', value: true })
        }
        if (display_name_search_value !== '') {
            filters.push({ field: 'display_name', op: 'contains', value: display_name_search_value })
        }
        const params = make_filter_params(filters)
        if (props.publicList) {
            params['public'] = true
        }

        if (props.publicList) {
            const sI = await listStoreItems(props.location.pathname.split('/')[2], params)
            setStoreItems(sI.data.results)

        }

        else {
            // const userSchools = [].concat(currentUser.guardian_schools).concat(currentUser.school_staff_memberships)
            let userSchoolIds = []
            currentUser.guardian_schools.map(s => {
                userSchoolIds.push(s.id)
            })
            currentUser.school_staff_memberships.map(m => {
                if (!userSchoolIds.includes(m.school.id)) {
                    userSchoolIds.push(m.school.id)
                }
            })
            function getStoreItems() {
                return Promise.all(
                    userSchoolIds.map(async (id) => {
                        const r = await listStoreItems(id, params)
                        return r.data.results
                    })
                )
            }
            const sI = await getStoreItems()
                
            if (props.schoolBreakoutDisplay) {
                let breakoutSI = new Map()
                sI.map(s => {
                    if (s.length !== 0) {
                        breakoutSI.set(s[0].school, s)
                    }
                })
                setStoreItems([...breakoutSI])
            }
            else {
                setStoreItems(sI.flat(1))
            }
        }
        
    }


    React.useEffect(() => {
        async function initPage() {
            await doListStoreItems('')
            setLoading(false)
        }
        initPage()
    }, [])


    function handleRowClick(item) {
        history.push('/school/' + item.school.url_slug + '/item/' + item.id,)
    }


    async function handleSearch(value) {
        doListStoreItems(value)
    }

    const handleSearchDebounced = useConstant(() =>
        AwesomeDebouncePromise(
            handleSearch,
            400
        )
    )


    if (loading) {
        return (<Preloader />)
    }

    if (props.schoolBreakoutDisplay) {
        return (
            <>
                <Typography>
                    <Box fontSize={22}>
                        {props.catPlural}
                    </Box>
                </Typography>
                <br />
                <TextField label='Search' onChange={e => handleSearchDebounced(e.target.value)} />
                <br /><br />
                {storeItems.length === 0 ? 'No ' + props.catPlural.toLowerCase() + ' found' :
                    <>
                        {storeItems.map(entry => {
                            const school = entry[0]
                            const itemsList = entry[1]
                            return (
                                <>
                                <Typography style={{fontSize: 20}}>
                                    {school.display_name}
                                </Typography>
                                <TableContainer
                                    component={Paper}
                                    style={{marginBottom: '2em'}}
                                >
                                    <Table>
                                        <TableBody>
                                            {itemsList.map(item => (
                                                <TableRow
                                                    hover
                                                    key={item.id}
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => handleRowClick(item)}
                                                >
                                                    <TableCell component='th' scope='row'>
                                                        <ListItemText
                                                            primary={item.display_name}
                                                            secondary={item.category_type === 'childcare' &&
                                                                        item.childcare_fields.plan_type.charAt(0).toUpperCase() + item.childcare_fields.plan_type.slice(1)
                                                                        }
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        {'$' + item.cost}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                </>
                            )
                        })}
                    </>
                }
            </>
        )
    }

    return (
        <>
            <Typography>
                <Box fontSize={22}>
                    {props.catPlural}
                </Box>
            </Typography>
            <br />
            <TextField label='Search' onChange={e => handleSearchDebounced(e.target.value)}/>
            <br /><br />
            {storeItems.length === 0 ? 'No ' + props.catPlural.toLowerCase() + ' found' :
                <TableContainer component={Paper}>
                <Table>
                    <TableBody>
                        {storeItems.map(item => (
                            <TableRow
                                hover
                                key={item.id}
                                style={{cursor: 'pointer'}}
                                onClick={() => handleRowClick(item)}
                            >
                                <TableCell component='th' scope='row'>
                                    <ListItemText
                                        primary={item.display_name}
                                        secondary={item.school.display_name}
                                    />
                                </TableCell>
                                <TableCell>
                                    {'$' + item.cost}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
        </TableContainer>
        }
        </>
    )

}