import ItemView from 'views/ItemView/ItemView'
import SchoolView from 'views/School/School'
import Donations from 'views/Donations/PublicDonations'


const schoolRoutes = [
    {
        path: '/*/item/*',
        name: 'Item',
        component: ItemView,
    },
    {
        path: '/*/donations',
        name: 'Donations',
        component: Donations
    },
    {
        path: '/*/',
        name: 'School',
        component: SchoolView
    },
]

export default schoolRoutes