let api_base = ''
if (process.env.REACT_APP_ENV == 'dev') {
    api_base = 'http://localhost:5000'
}
else if (process.env.REACT_APP_ENV == 'cloud-dev') {
    //api_base = 'http://34.67.167.124'
    api_base = 'https://api.dev.beta.charterpay.org'
}
else if (process.env.REACT_APP_ENV == 'demo') {
    api_base = 'https://api.demo.beta.charterpay.org'
}
else if (process.env.REACT_APP_ENV == 'prod') {
    api_base = 'https://api.charterpay.org'
}

export { api_base }


export async function api_call(path, method='GET', body={}, params={}){
    var url = new URL(path, api_base);
    url.search = new URLSearchParams(params).toString();

    const h = new Headers();
    h.append('Content-Type', 'application/json')
    var o = {
        credentials: 'include',
        method: method,
        headers: h
    }
    if (!['GET', 'HEAD'].includes(method)){
        if (typeof(body) === 'object'){
            body = JSON.stringify(body)
        }
        o['body'] = body
    }
    return await fetch(url.toString(), o);
    //const response = await fetch(url.toString(), o);
    //const rJson = await response.json();
    //return {status: response.status, data: rJson}
}

export async function make_response_object(r){
    const data = await r.json()
    return {ok: r.ok, status: r.status, data: data}
}


export function make_filter_params(filters) {
    // filters = [ {field: field, op: op, value: value}... ]

    var params = {}
    filters.map(filter => {
        params[filter.field + '[' + filter.op + ']'] = filter.value
    })

    return params
}


export function make_params_from_react_table(state){
    // page_params = [page, per_page]
    // filter_params = [ [field, op]... ]

    var params = {}
    state.filtered.map(o => {
        params[o.id + '[contains]'] = o.value
    })
    if (isNaN(params['page'])){
        params['page'] = 0
    }
    params['page'] = state.page + 1;
    params['per_page'] = state.pageSize;

    return params;
}