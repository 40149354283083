import React from 'react';
import styles from "assets/jss/storefront/views/dashboardStyle.js";
import { makeStyles } from '@material-ui/core/styles';
import StoreItemList from 'components/StoreItemList/StoreItemList'

const useStyles = makeStyles(styles);


export default function DonationsPage(props) {
    const classes = useStyles()


    return (
        <StoreItemList
            category='donations'
            catSingular='Donation'
            catPlural='Donations'
            publicList
            openItemsOnly
            location={props.location}
        />
    )
}