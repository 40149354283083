import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faUserCircle, faShoppingCart, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import styles from "assets/jss/storefront/components/navbarStyle.js";
import { Box, Typography, Grid, Button } from "@material-ui/core";
import logo from 'assets/img/csplogo.png';
import { useHistory, Link } from "react-router-dom";
import { UserContext } from 'components/UserProvider.js'
import { CartContext } from 'components/CartProvider.js'
import { logout } from 'api/auth'

const useStyles = makeStyles(styles);

export default function Header(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [currentUser, setCurrentUser, currentUserIsSet] = React.useContext(UserContext)
    const currentCart = React.useContext(CartContext)[0]
    const open = Boolean(anchorEl);
    const profileRef = React.useRef(null)
    let history = useHistory()
    const cUserLoggedIn = Object.keys(currentUser).length !== 0
    let cUserIsGuardian = false
    if (currentUser.hasOwnProperty('guardian_grants') && currentUser.guardian_grants.length !== 0) {
        cUserIsGuardian = true
    }
    //const displayGuardianPortalButton = cUserLoggedIn && cUserIsGuardian && props.baseUrl !== '/guardians'
    const displayGuardianPortalButton = false

    const { color } = props;
    const appBarClasses = classNames({
        [" " + classes[color]]: color
    });

    const handleMenu = event => {
        //setAnchorEl(event.currentTarget);
        setAnchorEl(profileRef.current)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    async function handleLogout() {
        await logout()
        localStorage.removeItem('appUser')
        setCurrentUser({})
        window.location = '/login'
    }

    let logoLink = null
    if (!currentUserIsSet() || (currentUser.guardian_grants.length === 0 && currentUser.school_staff_memberships.length === 0) ) {
        logoLink = '/'
    }
    else if (currentUser.guardian_grants.length !== 0 || currentUser.school_staff_memberships.length !== 0) {
        logoLink = '/guardians/dashboard'
    }
    else {
        logoLink = '/'
    }

    return (
        <AppBar className={classes.appBar + appBarClasses} position='sticky'>
            <Toolbar className={classes.container}>
                <div className={classes.flex}>
                    <Box>
                        <Grid container direction='row' alignItems='center'>
                                <Grid item style={{width: '50px'}}>
                                    <Link to={logoLink}>           
                                        <img src={logo} alt='Charter Pay' style={{height: '50px', width: '50px', objectFit: 'cover'}}/>
                                    </Link>
                                </Grid>
                                <Hidden xsDown>
                                <Grid item>
                                    <Typography
                                        style={{paddingLeft: '10px', color: '#fff', 'font-style': 'normal',}}
                                        align='center'
                                        justify='center'
                                    >
                                        Charter Pay
                                    </Typography>
                                </Grid>
                                </Hidden>
                        </Grid>
                    </Box>
                </div>
                {displayGuardianPortalButton &&
                    <Button
                        variant='contained'
                        color='primary'
                        href='/guardians/dashboard'
                        style={{ marginRight: '1em' }}
                    >
                        Guardian/Staff Portal
                </Button>
                }
                
            <IconButton
                onClick={() => history.push('/need-help')}
                color='inherit'
                style={{}}
            >
                <FontAwesomeIcon icon={faQuestionCircle} />
            </IconButton>
            <Typography
                onClick={() => history.push('/need-help')}
                style={{cursor: 'pointer', marginRight: 40}}
            >
                    Need Help?
            </Typography>
            <Typography
                onClick={() => history.push({
                    pathname: '/cart',
                    state: {step: 'view-cart'}
                })}
                style={{cursor: 'pointer'}}
            >
                    {currentCart.length}
            </Typography>
            <IconButton
                    onClick={() => history.push({
                        pathname: '/cart',
                        state: { step: 'view-cart' }
                    })}
                color='inherit'
                style={{marginRight: 15}}
            >
                <FontAwesomeIcon icon={faShoppingCart} />
            </IconButton>
            <Typography
                onClick={handleMenu}
                style={{ cursor: 'pointer' }}
            >
                {currentUser.first_name}
            </Typography>
            <IconButton
                onClick={handleMenu}
                color='inherit'
                ref={profileRef}
            >
                <FontAwesomeIcon icon={faUserCircle} />
            </IconButton>
            <Menu
                id='menu-navbar'
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={open}
                onClose={handleClose}
                    getContentAnchorEl={null}
            >
                {currentUserIsSet() ? (
                    [
                        <MenuItem onClick={() => history.push('/profile')}>My Account</MenuItem>,
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>,
                    ]
                ) : (
                    <MenuItem onClick={() => history.push('/login')}>Login</MenuItem>
                )
                }
                
            </Menu>
            </Toolbar>
        </AppBar>
    );
}

Header.propTypes = {
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
    rtlActive: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    routes: PropTypes.arrayOf(PropTypes.object)
};
