import { faCalendarTimes } from '@fortawesome/free-solid-svg-icons';
import { faCreditCard, faMoneyCheck, faTrashAlt, faHandHoldingUsd, faUtensils, faDollarSign, faBusAlt } from '@fortawesome/free-solid-svg-icons';
import { faBellSchool } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, TableContainer, ListItemText, TableCell, Paper, Table, TableBody, TableRow, IconButton } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { make_filter_params } from 'api/base';
import {listSubscriptions, deactivateSubscription} from 'api/subscriptions'
import Preloader from 'components/Preloader/Preloader';
import { UserContext } from 'components/UserProvider.js';
import currency from 'currency.js';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React from 'react';


export default function SubscriptionsView(props) {
    const currentUser = React.useContext(UserContext)[0]
    const [subscriptions, setSubscriptions] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const { enqueueSnackbar } = useSnackbar()


    React.useEffect(() => {
        async function init() {
            let filters = []
            const params = make_filter_params(filters)
            const r = await listSubscriptions(currentUser.id, params)
            if (r.ok) {
                setSubscriptions(r.data.results)
            }
            else {
                enqueueSnackbar('There was an error loading your subscriptions.', { variant: 'error' })
            }
            setLoading(false)
        }

        init()
    }, [])


    async function handleDeactivateSubscription(subscriptionId, idx) {
        const r = await deactivateSubscription(currentUser.id, subscriptionId)
        if (!r.ok) {
            enqueueSnackbar('There was an error deactivating your subscription.', { variant: 'error' })
            return
        }
        
        let subs = subscriptions.slice()
        subs.splice(idx, 1)
        setSubscriptions(subs)

        enqueueSnackbar('Subscription deactivated.', { variant: 'success' })
    }


    const itemCategoryDisplayName = {
        'donations': 'Donations',
        'lunches': 'Lunches',
        'childcare': 'Before & After School / Childcare',
        'fees': 'Fees',
        'activities': 'Activities & Field Trips',
    }

    const itemCategoryIcon = {
        'donations': faHandHoldingUsd,
        'lunches': faUtensils,
        'childcare': faBellSchool,
        'fees': faDollarSign,
        'activities': faBusAlt,
    }


    if (loading) { return <Preloader /> }

    if (subscriptions.length === 0) {
        return (
            <Typography>
                No active subscriptions were found.
            </Typography>
        )
    }


    return (
        <>
            <Typography style={{fontSize: 22}}>
                Your Active Subscriptions
            </Typography>
            <br />
            <TableContainer component={Paper}>
                <Table>
                    <TableBody>
                        {subscriptions.map((s, i) => (
                            <TableRow hover key={i}>
                                <TableCell component='th' scope='row'>
                                    <ListItemText
                                        primary={s.store_item.display_name}
                                        secondary={s.store_item.school.display_name}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Grid
                                        container
                                        alignItems='center'
                                        spacing={1}
                                    >
                                        <Grid item>
                                            <FontAwesomeIcon
                                                icon={itemCategoryIcon[s.store_item.category_type]}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <ListItemText
                                                primary={itemCategoryDisplayName[s.store_item.category_type]}
                                            />
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell>
                                    {s.store_item.category_type === 'childcare' &&
                                        <ListItemText
                                            primary={s.students.map((stu, idx) => (
                                                stu.first_name + (idx === s.students.length - 1 ? '' : ', ')
                                            ))}
                                        />
                                    }
                                </TableCell>
                                <TableCell>
                                    {'$' + currency(s.cost).format()}
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        onClick={() => handleDeactivateSubscription(s.id, i)}
                                        color='inherit'
                                    >
                                        <FontAwesomeIcon icon={faCalendarTimes} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}