import React from 'react';
import 'assets/css/styles.css';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import styles from "assets/jss/storefront/views/dashboardStyle.js";
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Grid, IconButton, Button } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingUsd, faUtensils, faClock, faDollarSign, faBusAlt } from '@fortawesome/free-solid-svg-icons';
import { faBellSchool } from '@fortawesome/pro-solid-svg-icons'
import { useHistory, Link } from "react-router-dom";
import CardButton from 'components/CardButton/CardButton'
import { listUnpaidItems } from 'api/customerorders'
import { make_filter_params } from 'api/base';
import { UserContext } from 'components/UserProvider.js';
import Preloader from 'components/Preloader/Preloader';
import { getSchool } from 'api/school'



const useStyles = makeStyles(styles);


export default function SchoolView(props) {
    const currentUser = React.useContext(UserContext)[0]
    const [school, setSchool] = React.useState({})
    const [loading, setLoading] = React.useState(true)
    let cUserIsGuardian = false
    if (currentUser.hasOwnProperty('guardian_grants') && currentUser.guardian_grants.length !== 0) {
        cUserIsGuardian = true
    }

    
    React.useEffect(() => {
        if (props.location.state && props.location.state.school) {
            setSchool(props.location.state.school)
            setLoading(false)
            return
        }

        async function init() {
            const r = await getSchool(
                props.location.pathname.split('/')[2],
                {'public': true}
                )
            if (!r.ok) {
                setLoading(false)
                return
            }
            setSchool(r.data)
            setLoading(false)
        }
        init()

    }, [])


    if (loading) {
        return <Preloader />
    }

    return (
        <div className="maincolumns w-row">
            <div className="maincolumn w-col w-col-10">
                <Typography
                    style={{marginBottom: '.5em', fontSize: 30}} 
                >
                    {school.display_name} Marketplace
                </Typography>
                {cUserIsGuardian &&
                    <>
                        <Grid item style={{ marginTop: '1em'}}>
                            <Typography style={{ fontSize: 16 }}>
                                Looking for field trips, fees, and lunches? Go to the Guardian/Staff Portal.
                </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                variant='contained'
                                color='primary'
                                href='/guardians/dashboard'
                                style={{  marginBottom: '2em' }}
                            >
                                Guardian/Staff Portal
                </Button>
                        </Grid>
                    </>
                }
                <Grid container>
                    <Grid item>
                        <CardButton
                            linkTo={props.location.pathname + '/donations'}
                            icon={faHandHoldingUsd}
                            text='Donations'
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}