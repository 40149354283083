import React from 'react';
import 'assets/css/styles.css';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import styles from "assets/jss/storefront/views/dashboardStyle.js";
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Grid, IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingUsd, faUtensils, faClock, faDollarSign, faBusAlt } from '@fortawesome/free-solid-svg-icons';
import { faBellSchool } from '@fortawesome/pro-solid-svg-icons'
import { useHistory, Link } from "react-router-dom";
import CardButton from 'components/CardButton/CardButton'
import { listUnpaidItems } from 'api/customerorders'
import { make_filter_params } from 'api/base';
import { UserContext } from 'components/UserProvider.js';
import Preloader from 'components/Preloader/Preloader';



const useStyles = makeStyles(styles);


export default function Dashboard() {
    const classes = useStyles()
    const currentUser = React.useContext(UserContext)[0]
    const [unpaidItemsLoading, setUnpaidItemsLoading] = React.useState(true)
    const [unpaidItems, setUnpaidItems] = React.useState({})


    React.useEffect(() => {
        async function initUnpaidItems() {
            let filters = [{ field: 'approval_status', op: 'eq', value: 'approved'}]
            const params = make_filter_params(filters)
            const r = await listUnpaidItems(currentUser.id, params)
            if (r.ok) {
                setUnpaidItems(r.data)
            }
            else {
                setUnpaidItems({item_count: 0})
            }
            setUnpaidItemsLoading(false)
        }

        initUnpaidItems()
    }, [])


    return(
        <div className="maincolumns w-row">
            <div className="sidebarcolumn w-col w-col-2">
                <div className="sidebar">
                    <Card raised>
                        <CardContent>
                        <Typography className={classes.cardTitle}>Announcements</Typography>
                        </CardContent>
                    </Card>
                    <br />
                    <Card raised>
                        <CardContent>
                            {unpaidItemsLoading ? 
                                <Preloader
                                    styles={{display: 'block', width: '30%', height: 'auto', margin: 'auto'}}
                                /> :
                                <Typography className={classes.cardTitle}>
                                    {unpaidItems.item_count} Unpaid Fees
                                </Typography>
                            }
                            {unpaidItemsLoading || unpaidItems.item_count === 0 ? null :
                                Object.keys(unpaidItems.students_items).map(stuId => (
                                    unpaidItems.students_items[stuId].items.map(item => (
                                        <Link
                                            to={{
                                                pathname: '/school/' + item.school_id + '/item/' + item.id,
                                                state: {
                                                    preSelectStudent: stuId
                                                }
                                            }}
                                        >
                                            <Typography>
                                                {item.display_name} - {unpaidItems.students_items[stuId].student.first_name}
                                            </Typography>
                                        </Link>
                                    ))
                                ))
                            }
                        </CardContent>
                    </Card>
                </div>
            </div>
            <div className="maincolumn w-col w-col-10">
                <Grid container>
                    <Grid item>
                        <CardButton
                            linkTo='donations'
                            icon={faHandHoldingUsd}
                            text='Donations'
                        />
                    </Grid>
                    <Grid item>
                        <CardButton
                            linkTo='lunches'
                            icon={faUtensils}
                            text='Lunches'
                        />
                    </Grid>
                    <Grid item>
                        <CardButton
                            linkTo='childcare'
                            icon={faBellSchool}
                            text='Before & After School / Childcare'
                        />
                    </Grid>
                    <Grid item>
                        <CardButton
                            linkTo='fees'
                            icon={faDollarSign}
                            text='Fees'
                        />
                    </Grid>
                    <Grid item>
                        <CardButton
                            linkTo='activities'
                            icon={faBusAlt}
                            text='Activities & Field Trips'
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}